import React from "react";

export const AgentCard = ({ data, isSelected, onSelect }) => {

  return (
    <div
      className={`bg-[#101010] text-white p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-xl transition-shadow `}
      onClick={onSelect}
    >
      <div className="flex flex-col h-full gap-6">
        <button
          className={`px-4 py-1 border-[0.5px] border-gray-700 rounded-md flex justify-center items-center gap-2 text-lg transition-all ${
            isSelected ? "bg-[#007AFF] " : "bg-gray-400 hover:bg-gray-500"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onSelect();
          }}
        >
          Select
        </button>
        <h2 className="text-[16px] text-[#FFFFFF] font-bold mb-4">
          {data.title}
        </h2>
        <p className="mb-4 text-gray-400">{data.description}</p>
        {/* <ul className="flex-grow list-disc list-outside pl-4">
          <li className="mb-2 text-[#9E9E9E] text-[14px]">
            {stripHtmlTags(data.feature_actions)}
          </li>
        </ul> */}
        <div
          className="feature-actions"
          dangerouslySetInnerHTML={{
            __html: `
        ${data.feature_actions}
    `,
          }}
        />
      </div>
    </div>
  );
};
