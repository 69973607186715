import React from "react";

export const Step1 = ({ formData, setFormData, handleNext }) => {
  const isNextDisabled =
    formData.name.trim() === "" || formData.description.trim() === "";

  return (
    <div className="w-[90%] bg-[#212121] px-16 py-6 flex flex-col gap-6 rounded-lg">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row  items-center gap-4">
          <div className="w-6 h-6 mt-1 rounded-md bg-white text-center text-black">
            1
          </div>
          <p className="text-[32px] font-semibold  text-white">
            Let's set up a new agent!
          </p>
        </div>
        <div className="w-full bg-[#101010] h-[1px]"></div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="">
          <label htmlFor="name" className="block text-[#BDBDBD] mb-2">
            Agent Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter agent name"
            className="w-full px-4 py-2 bg-[#101010] border-[#101010] rounded-md focus:outline-none text-white "
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </div>
        <div className="">
          <label htmlFor="description" className="block text-[#BDBDBD] mb-2">
            Agent Description
          </label>
          <textarea
            id="description"
            rows={1}
            placeholder="Enter agent description"
            className="w-full px-4 py-2 bg-[#101010] border-[#101010] rounded-md focus:outline-none text-white"
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            required
          ></textarea>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleNext}
          disabled={isNextDisabled}
          className={`w-[13%] px-4 py-2 text-white  rounded-md ${
            isNextDisabled ? "bg-[#616161]" : "bg-[#007AFF] hover:bg-blue-600"
          } focus:outline-none`}
        >
          Next
        </button>
      </div>
    </div>
  );
};
