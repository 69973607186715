import React from "react";

const LabelInput = ({ heading, name, formData, handleInputChange }) => {
  return (
    <div className="w-full mx-auto">
      <label className="block text-dark-6 text-sm mb-2">{heading}</label>
      <input
        type="text"
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        className="w-full border border-dark-7 p-2"
        // placeholder="Enter Notion Page URL"
      />
    </div>
  );
};

export default LabelInput;
