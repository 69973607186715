import moment from "moment";
export const capitalizeFirstLetter = (string) => {
  if (string?.length === 0) return string; // Handle empty string case
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const getFirstLetter = (str) => {
  if (str && str?.length > 0) {
    return str?.charAt(0); // or str[0]
  }
  return ""; // Return an empty string if the input is empty or null
};

export const calculateTimeElapsed = (taskPerformedAt) => {
  const taskTime = moment.utc(taskPerformedAt);
  const now = moment.utc();
  const duration = moment.duration(now.diff(taskTime)); // Calculate the difference
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.minutes());
  const seconds = Math.floor(duration.seconds());
  return `${hours} hours ${minutes} minutes and ${seconds} seconds ago`;
};
