/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import AgentTaskChart from "./AgentTaskChart";
import TimeSavedChart from "./TimeSavedChart";
import HalfDonut from "./HalfDount";
import AgentTable from "../table/AgentTable";
import { selectUser, setAgents } from "../../redux";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchOverviewReport } from "../../api/overview";
import { useDispatch } from "react-redux";
import { getAgentOverviewReport, setAgentOverviewReport } from "../../redux";
import { getAgentsById } from "../../api/agent";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);

const getFirstAndLastDateOfMonth = () => {
  const today = new Date();

  // Set the end date as today
  const endDate = new Date(today);

  // Set the start date as 10 days before today
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 10);

  return [startDate, endDate];
};

const GraphComponent = () => {
  const [dateRange, setDateRange] = useState(getFirstAndLastDateOfMonth());
  const [startDate, endDate] = dateRange;
  const [labels, setLabels] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [TasksPricePerDay, setTasksPricePerDay] = useState(1);
  const user = localStorage.getItem("userDetails");
  const parse = JSON.parse(user);
  const dispatch = useDispatch();
  const overviewReport = useSelector(getAgentOverviewReport);
  const userDetail = useSelector(selectUser);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const start = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1 < 10 ? "0" : ""
      }${startDate.getMonth() + 1}-${
        startDate.getDate() < 10 ? "0" : ""
      }${startDate.getDate()}`;

      const end = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1 < 10 ? "0" : ""
      }${endDate.getMonth() + 1}-${
        endDate.getDate() < 10 ? "0" : ""
      }${endDate.getDate()}`;

      const obj = {
        client_id: parse?.client_id,
        start_date: start,
        end_date: end,
      };

      fetchOverviewReport(obj)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAgentOverviewReport(res?.data));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [dateRange]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const start = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1 < 10 ? "0" : ""
      }${startDate.getMonth() + 1}-${
        startDate.getUTCDate() < 10 ? "0" : ""
      }${startDate.getUTCDate()}`;

      const end = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1 < 10 ? "0" : ""
      }${endDate.getMonth() + 1}-${
        endDate.getUTCDate() < 10 ? "0" : ""
      }${endDate.getUTCDate()}`;

      const obj = {
        client_id: parse?.client_id,
        start_date: start,
        end_date: end,
      };

      fetchOverviewReport(obj)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAgentOverviewReport(res?.data));
          }
        })
        .catch((err) => console.log(err));
      getAgentsById(parse?.client_id)
        .then((res) => {
          if (res?.status === 200) {
            dispatch(setAgents(res?.data));
          } else if (res?.response?.status === 400) {
            dispatch(setAgents([]));
          } else {
            toast.error(res?.response?.data?.error_message);
          }
        })
        .catch((err) => console.log("agent", err));
    }
  }, []);

  useEffect(() => {
    if (typeof overviewReport === "object" && overviewReport !== null) {
      const taskPrices = overviewReport?.total_cost_per_day || {};

      setLabels(
        Object.keys(taskPrices).map((date) =>
          new Date(date).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
          })
        )
      );

      setDataPoints(Object.values(taskPrices));
      setTasksPricePerDay(taskPrices);
    }
  }, [overviewReport]);
  return (
    <div className=" text-white min-h-screen p-8 space-y-8 customScroll">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <h1 className="text-2xl font-semibold">
            Welcome back, {userDetail?.name}
          </h1>
        </div>
        <div className="flex space-x-4 items-center">
          <DatePicker
            selected={startDate}
            onChange={(update) => setDateRange(update)}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable={true}
            className="bg-gray-800 text-gray-400 rounded-lg px-5 py-3 focus:outline-none focus:ring"
            placeholderText="Max range is 10 days"
            maxDate={
              startDate
                ? new Date(startDate.getTime() + 9 * 24 * 60 * 60 * 1000)
                : null
            } // Limit to 12 days from the start date
          />

          {/* <button className="flex items-center gap-2 bg-[#0A1330] text-white p-2 rounded-md">
            <p className="font-medium text-xs">Export data</p> <GoArrowDown />
          </button>
          <button className="bg-[#2D76E2] text-white p-2 rounded-md">
            <p className="font-medium text-xs">Create report</p>
          </button> */}
        </div>
      </div>

      {/* Statistics Section */}
      <div className="w-full flex bg-gray-900">
        {/* Agent tasks Completed */}
        <AgentTaskChart
          labels={labels}
          dataPoints={dataPoints}
          TasksPricePerDay={TasksPricePerDay}
          totalCompletedTasks={overviewReport?.total_task_count}
        />

        <div className="w-[35%]">
          {/* Time Saved */}
          <TimeSavedChart
            daily_time_saved_in_hours={
              overviewReport?.daily_time_saved_in_hours
            }
            totalTimeSaved={overviewReport?.total_time_saved_in_hours}
          />

          {/* Workflow Usage */}
          <HalfDonut workflow={overviewReport?.workflow_usage} />
        </div>
      </div>

      {/* Tasks Section */}
      <AgentTable
        data={overviewReport?.agent_escalations}
        total_agent_escalations={overviewReport?.total_agent_escalations}
        total_escalations_percentage={
          overviewReport?.total_escalations_percentage
        }
      />
    </div>
  );
};

export default GraphComponent;
