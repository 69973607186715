import React, { useEffect, useRef, useState } from "react";
import Settings from "../../assets/icons/Settings";

export default function FilterMenu({ options, selectFilter }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState("Most Popular");
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedSort(option);
    selectFilter(option);
    // dispatch(setAgentTaskFilter(option));
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left p-4" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="group inline-flex justify-center text-sm font-medium text-[#87888C] items-center gap-1"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Settings />
          Filter
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-1 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`w-full block px-4 py-2 hover:bg-gray-400 hover:text-white text-sm ${
                  selectedSort === option
                    ? "font-medium text-gray-900"
                    : "text-gray-500"
                }`}
                role="menuitem"
                tabIndex="-1"
                id={`menu-item-${index}`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
