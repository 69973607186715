import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { BsGraphUpArrow } from "react-icons/bs";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TimeSavedChart = ({ daily_time_saved_in_hours, totalTimeSaved }) => {
  const [time, setTime] = useState([]);
  const [keys, setKeys] = useState([]);
  useEffect(() => {
    if (
      daily_time_saved_in_hours &&
      typeof daily_time_saved_in_hours === "object"
    ) {
      // Extract keys and values from the object
      const newKeys = Object.keys(daily_time_saved_in_hours);
      const newValues = Object.values(daily_time_saved_in_hours);

      // Update state
      setKeys(newKeys);
      setTime(newValues);
    }
  }, [daily_time_saved_in_hours]);
  const data = {
    labels: keys,
    datasets: [
      {
        label: "Hours",
        data: time,
        backgroundColor: ["#4b5dfb"], // Gradient-like color effect
        borderRadius: 5,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#1f2937",
        titleColor: "#fff",
        bodyColor: "#fff",
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        displayColors: false,
        callbacks: {
          label: (context) => `${context.raw} Hours Saved`,
          title: (tooltipItems) => {
            const index = tooltipItems[0]?.dataIndex;
            const date = keys[index];
            return `${date} `;
          },
        },
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
        grid: {
          display: false,
        },
        ticks: {
          color: "#9ca3af",
        },
      },
      y: {
        display: false, // Hide x-axis
        grid: {
          color: "#374151",
        },
        ticks: {
          color: "#9ca3af",
          beginAtZero: true,
          maxTicksLimit: 5,
        },
      },
    },
    maintainAspectRatio: false,
  };
  return (
    <div className="bg-gray-900 p-5 rounded-lg shadow-lg text-white w-full max-w-sm">
      <div className="mb-5">
        <div className="flex justify-between items-center">
          <div className="flex text-sm text-gray-400 items-center gap-2">
            <BsGraphUpArrow />
            Time saved
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="text-xl text-white">{totalTimeSaved}</div>
        </div>
      </div>
      <div className="h-20">
        {data?.datasets[0]?.data?.length > 0 ? (
          <Bar data={data} options={options} />
        ) : (
          <div className="h-32 text-center text-white py-4">No Time Saved</div>
        )}
      </div>
      <div className="text-gray-400 text-sm mt-4">Last 10 days</div>
    </div>
  );
};

export default TimeSavedChart;
