import React from "react";
import AgentDashboard from "../components/agentDetail/AgentDashboard";

export default function AgentsDetailPage() {
  return (
    <>
      <AgentDashboard />
    </>
  );
}
