import React from "react";

export const AxeIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="22" fill="#7AC6FF" />
      <g clip-path="url(#clip0_307_6101)">
        <path
          d="M27.2505 11.8729C25.0455 14.1875 23.7549 14.8491 21.4454 17.9723C18.4869 21.9735 15.3936 29.1286 13.4969 33.5688C13.2638 34.113 12.5887 34.3244 12.0756 34.014C12.0589 34.0038 12.0422 33.9936 12.0255 33.9834C11.4528 33.6352 10.8801 33.2891 10.3064 32.9409C9.80164 32.6346 9.68668 31.9608 10.0702 31.5146C13.4739 27.5552 14.5325 23.6857 17.6132 18.0049C18.6886 16.0222 21.1538 13.4769 22.8395 11.2031C23.8019 9.90342 25.6203 9.53995 27.0154 10.3874L27.0488 10.4068C27.3864 10.611 27.5463 10.9785 27.5045 11.3318C27.4836 11.5268 27.4 11.7167 27.2505 11.8729Z"
          fill="#996633"
        />
        <path
          d="M25.9873 11.684C25.5693 11.8984 25.1962 12.2078 24.9026 12.6039C24.2129 13.5351 23.3915 14.5132 22.5878 15.478L20.5176 13.7505C20.3859 13.6402 20.3703 13.4463 20.4831 13.3176L23.4259 9.95142C23.5388 9.82278 23.7374 9.80644 23.869 9.91773L25.9873 11.684Z"
          fill="#F5F5F5"
        />
        <path
          d="M27.1333 12.6396L23.7829 16.4744L23.1162 15.918C24.3734 14.5284 25.4111 13.7351 26.7864 12.3496L27.1333 12.6396Z"
          fill="#F5F5F5"
        />
        <path
          d="M26.7853 12.3496C25.41 13.7351 24.3723 14.5284 23.1151 15.918L22.5874 15.4779C23.391 14.5131 24.2124 13.535 24.9021 12.6039C25.1958 12.2077 25.5699 11.8984 25.9869 11.684L26.7853 12.3496Z"
          fill="#DDDDDD"
        />
        <path
          d="M23.1156 15.9179C22.6066 16.4785 22.0632 17.137 21.4456 17.9732C18.4871 21.9734 15.3938 29.1285 13.4971 33.5687C13.2641 34.1129 12.589 34.3243 12.0759 34.0139C12.0591 34.0037 12.0424 33.9935 12.0257 33.9833C11.8418 33.6525 16.5967 25.0854 19.6764 19.4056C20.3107 18.2346 21.431 16.8685 22.5878 15.4779L23.1156 15.9179Z"
          fill="#825A2C"
        />
        <path
          d="M27.2507 11.8729C27.0918 12.0403 26.9372 12.1985 26.7867 12.3497L25.9883 11.684C26.4565 11.443 26.979 11.3225 27.5057 11.3328C27.4837 11.5267 27.4001 11.7166 27.2507 11.8729Z"
          fill="#825A2C"
        />
        <path
          d="M34.0832 16.3559L28.6135 22.6136C28.4787 22.7677 28.231 22.7534 28.1161 22.584L28.0492 22.4859L24.1313 16.6929L27.4995 12.9418L33.8648 15.8312L33.9766 15.8812C34.1647 15.9659 34.218 16.2018 34.0832 16.3559Z"
          fill="#F5F5F5"
        />
        <path
          d="M34.0828 16.356L28.6131 22.6136C28.4783 22.7677 28.2307 22.7535 28.1157 22.584L28.0488 22.486L33.8644 15.8312L33.9763 15.8812C34.1644 15.9659 34.2177 16.2018 34.0828 16.356Z"
          fill="#DDDDDD"
        />
      </g>
      <defs>
        <clipPath id="clip0_307_6101">
          <rect
            width="24.3158"
            height="24.3158"
            fill="white"
            transform="translate(9.84229 9.84216)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
