import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getKnowledgeBaseID } from "../../../api/knowledgebase";

export const KBNameCard = ({ config, onUpdate }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState(config);
  const [kbList, setKbList] = useState([]);

  const handleCardClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const parseData = () => {
      try {
        if (config?.config_json) {
          const parsedData = JSON.parse(config.config_json);
          setFormData(parsedData);
        }
      } catch (error) {
        console.error("Error parsing config_json:", error);
      }
    };

    parseData();
  }, [config]);

  useEffect(() => {
    const fetchKnowledgeBaseID = async () => {
      try {
        const fetchKBName = await getKnowledgeBaseID();
        setKbList(fetchKBName);
      } catch (error) {
        console.error("Error fetching knowledge base:", error);
      }
    };

    fetchKnowledgeBaseID();
  }, []);

  const kbName = kbList?.find(
    (kb) => kb?.id === config?.knowledge_base_integration_id
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        name: formData?.knowledge_base_integration_type || formData?.name,
      };
      toast.success("Knowledge Base is updated successfully!");

      onUpdate(config?.id, updatedFormData);
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error updating knowledge base:", error);
      toast.error("Failed to update knowledge base.");
    }
  };
  return (
    <>
      <div
        className="w-[8rem] px-4 flex min-h-[5rem] justify-center items-center min-w-[5rem] border border-dark-7 bg-agent-card rounded-md"
        onClick={handleCardClick}
      >
        <div className="flex items-center me-4">
          <input
            readOnly
            id="red-radio"
            type="radio"
            value=""
            name="colored-radio"
            className="w-4 h-4 text-gray-600 bg-gray-300 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="red-radio"
            className="ms-2 text-sm font-medium text-white dark:text-gray-400 flex flex-col"
          >
            {formData?.name}
          </label>
        </div>
      </div>

      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 drop-shadow-md">
          <div className="bg-agent-card dark:bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-400 min-w-44 w-[40%]">
            <h2 className="text-xl font-bold mb-4">
              {"Knowledge Base Details"}
            </h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  Google Drive Folder URL
                </label>
                <input
                  readOnly
                  type="url"
                  name="google_drive_url"
                  value={formData?.config_json?.google_drive_url}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded input bg-transparent"
                  placeholder="Enter drive link"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  Google Credentials Path
                </label>
                <input
                  readOnly
                  type="text"
                  name="google_creds_path"
                  value={formData?.config_json?.google_creds_path}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded input bg-transparent"
                  placeholder="Enter credentials path"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  Knowledge Base Integration Type
                </label>
                <input
                  readOnly
                  type="text"
                  name="knowledge_base_integration_type"
                  value={formData?.knowledge_base_integration_type}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded input bg-transparent"
                  placeholder={kbName?.name}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleCloseDialog}
                  className="px-4 py-2 mr-2 text-black bg-gray-300 rounded hover:bg-gray-400"
                >
                  Close
                </button>
                {/* <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Save
                </button> */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
