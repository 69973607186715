import React from "react";

export default function GoogleAuth({ formData, handleInputChange }) {
  return (
    <div className="w-[80%] mb-4 m-auto">
      <button
        type="button"
        className="w-full flex items-center justify-center bg-[#333333] text-white p-3 rounded-md hover:bg-[#080808] focus:outline-none focus:ring-2 focus:ring-gray-300"
      >
        <img
          src="https://img.icons8.com/color/48/000000/google-logo.png"
          alt="Google"
          className="h-5 w-5 mr-2"
        />
        <p className="text-white text-sm">Authenticate with Google</p>
      </button>
    </div>
  );
}
