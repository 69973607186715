import React from "react";

export default function GraghIcon({ color }) {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill={color || "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_159_246)">
        <path d="M4.5 18.1997C3.95 18.1997 3.479 18.004 3.087 17.6127C2.69567 17.2207 2.5 16.7497 2.5 16.1997V2.19971C2.5 1.64971 2.69567 1.17871 3.087 0.786707C3.479 0.395374 3.95 0.199707 4.5 0.199707H18.5C19.05 0.199707 19.521 0.395374 19.913 0.786707C20.3043 1.17871 20.5 1.64971 20.5 2.19971V16.1997C20.5 16.7497 20.3043 17.2207 19.913 17.6127C19.521 18.004 19.05 18.1997 18.5 18.1997H4.5ZM16.775 16.1997H18.5V14.4747L16.775 16.1997ZM5.35 16.1997H7.175L10.175 13.1997H12.3L9.3 16.1997H10.9L13.9 13.1997H16.025L13.025 16.1997H14.65L17.65 13.1997H18.5V2.19971H4.5V14.9247L6.225 13.1997H8.35L5.35 16.1997ZM7.15 11.1997L5.75 9.79971L10.175 5.37471L12.175 7.37471L15.85 3.69971L17.25 5.09971L12.175 10.1997L10.175 8.19971L7.15 11.1997Z" />
      </g>
      <defs>
        <clipPath id="clip0_159_246">
          <rect
            width="22"
            height="18"
            fill="white"
            transform="translate(0.5 0.199707)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
