import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CiClock2 } from "react-icons/ci";

ChartJS.register(ArcElement, Tooltip, Legend);

const HalfDonut = ({ workflow }) => {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState();
  const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const [workflowList, setWorkflowList] = useState([]);

  // Handle change event to update the state
  const handleSelectChange = (event) => {
    const id = event.target.value;
    setSelectedWorkflowId(id);
    const res = workflowList?.find(
      (item) => parseInt(item.id) === parseInt(id)
    );
    setSelectedWorkflow(res);
  };

  useEffect(() => {
    if (typeof workflow === "object" && workflow !== null) {
      const res = Object.keys(workflow).map((name) => ({
        name: name,
        id: workflow[name].workflow_id,
        workflow_cap_amount_in_minutes:
          workflow[name].workflow_cap_amount_in_minutes,
        total_usage_time_in_minutes: workflow[name].total_usage_time_in_minutes,
        avg_human_resolution_time_in_minutes_per_task:
          workflow[name].avg_human_resolution_time_in_minutes_per_task,
        total_tasks: workflow[name].total_tasks,
      }));
      setWorkflowList(res);
    }
  }, [workflow]);

  const data = {
    labels: [],
    datasets: [
      {
        label: ["Cap-Amount", "Usage"],
        data: [
          selectedWorkflow?.total_usage_time_in_minutes,
          selectedWorkflow?.workflow_cap_amount_in_minutes,
        ],
        backgroundColor: ["#4b5dfb", "#A259FF"],
        hoverBackgroundColor: ["#4b5dfb", "#A259FF"],
        borderWidth: 0,
        cutout: "90%", // Creates the donut
        rotation: 270, // Rotates the chart to create a half-donut
        circumference: 180, // Limits the chart to half circle
        tension: 0.4, // Adds the smooth curves
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#1f2937",
        titleColor: "#fff",
        bodyColor: "#fff",
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw} uses`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="bg-gray-900 p-5 rounded-lg shadow-lg text-white max-w-sm">
      <div className="mb-5 flex justify-between">
        <div className="text-sm text-gray-400 flex items-center gap-2">
          <CiClock2 />
          Current month workflow usage
        </div>
      </div>
      <select
        value={selectedWorkflowId}
        onChange={handleSelectChange}
        className="w-full bg-gray-800 mb-4 py-2 text-gray-400 text-[12px] rounded-lg px-1 focus:outline-none focus:ring"
      >
        <option selected value={""}>
          select workflow
        </option>
        {workflowList?.map((item, index) => {
          return (
            <>
              <option key={index} value={item?.id} className="text-[12px]">
                {item?.name?.length > 40
                  ? item.name.substring(0, 40) + "..."
                  : item.name}
              </option>
            </>
          );
        })}
      </select>
      {selectedWorkflowId && selectedWorkflowId !== "" ? (
        <>
          <div className="relative h-44">
            <Doughnut data={data} options={options} />
            <div className="absolute inset-0 flex flex-col items-center justify-end">
              <div className="text-3xl font-medium">
                {selectedWorkflow?.total_usage_time_in_minutes}
              </div>
              <div className="text-[#7E89AC] text-xs">Workflow Usage</div>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex items-center justify-between text-gray-400 text-sm">
              <div className="flex items-center">
                <span className="w-3 h-3 rounded-full bg-[#A259FF] mr-2"></span>
                Cap amount
              </div>
              <div>{selectedWorkflow?.workflow_cap_amount_in_minutes}</div>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex items-center justify-between text-gray-400 text-sm">
              <div className="flex items-center">
                <span className="w-3 h-3 rounded-full bg-[#4b5dfb] mr-2"></span>
                Usage
              </div>
              <div>{selectedWorkflow?.total_usage_time_in_minutes}</div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center min-h-[5rem]">
          <p className="text-center text-gray-500 ">
            Select WorkFlow for the details
          </p>
        </div>
      )}
    </div>
  );
};

export default HalfDonut;
