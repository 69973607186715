import React from "react";

export const HammerIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_76_2381)">
        <path
          d="M18.2505 2.87303C16.0455 5.1876 14.7549 5.8492 12.4454 8.97239C9.48689 12.9736 6.3936 20.1287 4.49687 24.569C4.26383 25.1132 3.58874 25.3245 3.07563 25.0141C3.05891 25.0039 3.04218 24.9937 3.02546 24.9835C2.45279 24.6353 1.88011 24.2892 1.30639 23.9411C0.801637 23.6348 0.686683 22.9609 1.07021 22.5147C4.47388 18.5554 5.5325 14.6858 8.61325 9.00506C9.68859 7.02231 12.1538 4.47699 13.8395 2.20326C14.8019 0.903545 16.6203 0.540074 18.0154 1.38749L18.0488 1.40689C18.3864 1.61109 18.5463 1.97864 18.5045 2.3319C18.4836 2.52691 18.4 2.71681 18.2505 2.87303Z"
          fill="#996633"
        />
        <path
          d="M16.9873 2.68416C16.5693 2.89857 16.1962 3.20792 15.9026 3.60407C15.2129 4.53521 14.3915 5.51331 13.5878 6.47814L11.5176 4.75063C11.3859 4.64037 11.3703 4.44638 11.4831 4.31774L14.4259 0.951546C14.5388 0.822902 14.7374 0.806566 14.869 0.917854L16.9873 2.68416Z"
          fill="#F5F5F5"
        />
        <path
          d="M18.1333 3.63957L14.7829 7.47439L14.1162 6.91795C15.3734 5.52839 16.4111 4.73509 17.7864 3.34961L18.1333 3.63957Z"
          fill="#F5F5F5"
        />
        <path
          d="M17.7853 3.34976C16.41 4.73524 15.3723 5.52855 14.1151 6.91811L13.5874 6.47806C14.391 5.51323 15.2124 4.53513 15.9021 3.60399C16.1958 3.20785 16.5699 2.89849 16.9869 2.68408L17.7853 3.34976Z"
          fill="#DDDDDD"
        />
        <path
          d="M14.1156 6.91807C13.6066 7.47859 13.0632 8.13713 12.4456 8.97332C9.48712 12.9735 6.39383 20.1286 4.49709 24.5689C4.26405 25.1131 3.58896 25.3244 3.07585 25.014C3.05913 25.0038 3.04241 24.9936 3.02569 24.9834C2.84176 24.6526 7.59666 16.0855 10.6764 10.4058C11.3107 9.23469 12.431 7.86861 13.5878 6.47803L14.1156 6.91807Z"
          fill="#825A2C"
        />
        <path
          d="M18.2507 2.87273C18.0918 3.04017 17.9372 3.19843 17.7867 3.34953L16.9883 2.68385C17.4565 2.4429 17.979 2.32242 18.5057 2.33263C18.4837 2.52662 18.4001 2.71652 18.2507 2.87273Z"
          fill="#825A2C"
        />
        <path
          d="M25.0832 7.35607L19.6135 13.6137C19.4787 13.7679 19.231 13.7536 19.1161 13.5841L19.0492 13.4861L15.1313 7.693L18.4995 3.94189L24.8648 6.83128L24.9766 6.88131C25.1647 6.96605 25.218 7.2019 25.0832 7.35607Z"
          fill="#F5F5F5"
        />
        <path
          d="M25.0828 7.35633L19.6131 13.6139C19.4783 13.7681 19.2307 13.7538 19.1157 13.5843L19.0488 13.4863L24.8644 6.83154L24.9763 6.88157C25.1644 6.96631 25.2177 7.20216 25.0828 7.35633Z"
          fill="#DDDDDD"
        />
      </g>
      <defs>
        <clipPath id="clip0_76_2381">
          <rect
            width="24.3158"
            height="24.3158"
            fill="white"
            transform="translate(0.842285 0.842285)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
