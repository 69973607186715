import "./RadioCard.css";
export const RadioCard = ({ text, type, sub }) => {
  // const [fileName, setFileName] = useState("File name here");

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setFileName(file.name);
  //   }
  // };
  return (
    <>
      <div className="w-[12rem] rounded-md px-3 flex min-h-[5rem] justify-center items-center min-w-[5rem] border border-dark-7 bg-[#222227]">
        <div className="flex items-center me-4">
          <label
            htmlFor="red-radio"
            className="ms-2 text-sm font-medium text-white dark:text-gray-400 flex flex-col"
          >
            {text}
            <span className="text-dark-7">{sub}</span>
          </label>
        </div>
      </div>
    </>
  );
};
