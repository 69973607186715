import React from "react";
import { calculateTimeElapsed } from "../../utils";

const TransactionHistory = ({ transactions }) => {
  return (
    <div className="bg-dark-3 text-white p-6 rounded-xl shadow-lg w-full max-h-[400px] simpleScroll">
      <div className="flex justify items-center mb-4 gap-4">
        <h2 className="text-lg font-semibold text-white">Activity History</h2>
        <div
          style={{ background: "rgba(87, 93, 255, 0.5)" }}
          className="text-[#D1DBF9] px-[6px] py-[2px] rounded-sm text-xs font-medium"
        >
          {transactions.length}
        </div>
      </div>
      <div className="space-y-6">
        {transactions?.length > 0 ? (
          transactions.map((transaction, index) => (
            <div
              key={index}
              className="flex justify-between items-start space-x-4"
            >
              <div className="w-full">
                <div className="w-[80%] flex justify-between">
                  <div className="font-normal text-xs text-white">
                    {transaction?.task_name}:
                  </div>
                  <div className="text-[#AEB9E1] font-medium text-xs">
                    {calculateTimeElapsed(transaction?.task_performed_at)}
                  </div>
                </div>
                <p className="text-[#7E89AC] font-normal text-xs mt-2">
                  {transaction?.task_summary}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="h-32 text-center text-white py-4">
            No Transactions Performed
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionHistory;
