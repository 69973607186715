import React, { useState, useEffect } from "react";
import "./Modal.css";

const Modal = ({ onClose, isConnected }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    "Getting Knowledge Base Agent...",
    "Getting our AI agent...",
    "Agent is training your knowledge base data...",
    "Assigning this to our AI agent...",
    "Initializing AI Agent to Knowledge Base...",
    "Connecting Knowledge Base...",
    "Our AI Agent has successfully understood your knowledge base data.",
    isConnected,
  ];
  useEffect(() => {
    if (messageIndex < messages.length - 1) {
      const timeout = setTimeout(() => {
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 500);

      return () => clearTimeout(timeout);
    } else {
      const closeTimeout = setTimeout(onClose, 500);
      return () => clearTimeout(closeTimeout);
    }
  }, [messageIndex, onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="loader"></div>
        <p className="text-white text-xl mt-4">{messages[messageIndex]}</p>
      </div>
    </div>
  );
};

export default Modal;
