import React from "react";

export default function RocketIcon({ color }) {
  return (
    <svg
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill={color || "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.49997 0.799805C7.49997 0.799805 11.6538 2.68288 11.6538 10.4921C11.6538 12.7906 10.6938 15.6337 10.1769 16.9537H4.82305C4.30613 15.6337 3.34613 12.7906 3.34613 10.4921C3.34613 2.68288 7.49997 0.799805 7.49997 0.799805ZM9.34613 8.64596C9.34613 7.63057 8.51536 6.7998 7.49997 6.7998C6.48459 6.7998 5.65382 7.63057 5.65382 8.64596C5.65382 9.66134 6.48459 10.4921 7.49997 10.4921C8.51536 10.4921 9.34613 9.66134 9.34613 8.64596ZM3.52151 17.4337C3.07843 16.2983 2.11843 13.5844 1.97997 11.0921L0.936895 11.7844C0.419972 12.1352 0.115356 12.7075 0.115356 13.326V18.7998L3.52151 17.4337ZM14.8846 18.7998V13.326C14.8846 12.7075 14.58 12.1352 14.063 11.7937L13.02 11.1013C12.8815 13.5844 11.9123 16.3075 11.4784 17.4429L14.8846 18.7998Z" />
    </svg>
  );
}
