import React from "react";

export default function WrenchIcon({ color }) {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill={color || "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_159_243)">
        <path d="M19.7809 18.5938L20.4809 17.8938L12.5059 9.91875C12.8226 9.53542 13.0686 9.10208 13.2439 8.61875C13.4185 8.13542 13.5059 7.62708 13.5059 7.09375C13.5059 5.84375 13.0686 4.78542 12.1939 3.91875C11.3186 3.05208 10.2559 2.61875 9.00588 2.61875H8.75588L11.8309 5.69375L7.58088 9.91875L4.50588 6.84375V7.11875C4.50588 8.36875 4.94322 9.42708 5.81788 10.2937C6.69322 11.1604 7.75588 11.5938 9.00588 11.5938C9.53922 11.5938 10.0436 11.5061 10.5189 11.3307C10.9936 11.1561 11.4226 10.9104 11.8059 10.5938L19.7809 18.5938ZM18.3809 20.0188L11.4809 13.1187C11.0976 13.2687 10.7019 13.3854 10.2939 13.4688C9.88522 13.5521 9.46422 13.5938 9.03088 13.5938C7.21422 13.5938 5.67688 12.9644 4.41888 11.7057C3.16022 10.4477 2.53088 8.91042 2.53088 7.09375C2.53088 6.39375 2.63522 5.71875 2.84388 5.06875C3.05188 4.41875 3.35588 3.81875 3.75588 3.26875L7.58088 7.09375L9.00588 5.69375L5.18088 1.84375C5.74755 1.42708 6.35588 1.11442 7.00588 0.90575C7.65588 0.69775 8.32255 0.59375 9.00588 0.59375C10.8226 0.59375 12.3602 1.22275 13.6189 2.48075C14.8769 3.73942 15.5059 5.27708 15.5059 7.09375C15.5059 7.52708 15.4642 7.95608 15.3809 8.38075C15.2976 8.80608 15.1726 9.21042 15.0059 9.59375L21.8809 16.4687C22.2642 16.8521 22.4602 17.3228 22.4689 17.8808C22.4769 18.4394 22.2892 18.9104 21.9059 19.2938L21.2309 19.9938C20.8476 20.3938 20.3766 20.5978 19.8179 20.6058C19.2599 20.6144 18.7809 20.4188 18.3809 20.0188Z" />
      </g>
      <defs>
        <clipPath id="clip0_159_243">
          <rect
            width="23.9382"
            height="20.0123"
            fill="white"
            transform="translate(0.530884 0.59375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
