import { createSlice } from "@reduxjs/toolkit";

export const workflowSlice = createSlice({
  name: "workflow",
  initialState: {
    selectedWorkflow: null,
    workflows: [],
    categories:[]
  },
  reducers: {
    setWorkflow: (state, action) => {
      state.selectedWorkflow = action.payload;
    },
    setAllWorkflows: (state, action) => {
      state.workflows = action.payload;
    },
    setWorkflowCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { setWorkflow, setAllWorkflows, setWorkflowCategories } = workflowSlice.actions;

export const getWorkflow = (state) => state.workflow.selectedWorkflow;
export const getAllWorkflow = (state) => state.workflow.workflows;
export const getCategories = (state) => state.workflow.categories;

export default workflowSlice.reducer;
