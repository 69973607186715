import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Filler
);

const AgentTaskChart = ({ labels, dataPoints, totalCompletedTasks }) => {

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Tasks completed",
        data: dataPoints,
        borderColor: "#00bfff",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens when the chart hasn't been rendered yet.
            return null;
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(0, 191, 255, 0.7)"); // Start color at the top with some opacity
          gradient.addColorStop(1, "rgba(55, 65, 81, 0.4)"); // End color at the bottom

          return gradient;
        },
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 5, // Increases the point radius on hover
        hitRadius: 10, // Increases the area where the point is considered hovered
        tension: 0.4, // Adds the smooth curves
        fill: true,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      scales: {
        beginAtZero: true,
      },
      tooltip: {
        backgroundColor: "#1f2937",
        titleColor: "#fff",
        bodyColor: "#fff",
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        displayColors: false,
        callbacks: {
          title: () => `Task Price`,
          label: (context) => `$${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#9ca3af",
          maxTicksLimit: 10,
        },
      },
      y: {
        grid: {
          color: "#374151",
        },
        ticks: {
          color: "#9ca3af",
          beginAtZero: true,
          maxTicksLimit: 10,
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{ borderRight: "1px solid #80808045" }}
      className="w-[65%] bg-gray-900 p-5"
    >
      <div className="w-full flex justify-between">
        <div className="flex flex-col justify-between mb-5">
          <div className="text-sm text-[#AEB9E1] font-medium">
            Agent tasks Completed
          </div>
          <div className="flex items-center gap-2">
            <div className="text-xl text-white">{totalCompletedTasks}</div>
          </div>
        </div>

        <div className="flex space-x-8">
          <div className="">
            <div className="text-sm text-gray-400 flex gap-2">
              <div className="text-[#00bfff]">●</div>Tasks completed
            </div>
          </div>
        </div>
      </div>
      <h2 className="m-0 p-0 flex justify-start items-start font-bold text-2xl ml-1 text-[#9ca3af]">
        $
      </h2>
      <div className="relative">
        <Line data={data} options={options} height={400} />
      </div>
    </div>
  );
};

export default AgentTaskChart;
