import React from "react";
import Slider from "react-slick";
import { WorkFlowCard } from "../WorkFlowCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const SimpleAgentCarousel = ({ agents }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    arrows: false,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {agents &&
          agents?.map((item) => {
            return (
              <WorkFlowCard
                name={item?.name}
                prompt={item?.agent_system_prompt}
                deployment={
                  item?.deployed_phone_number !== "" ? "Phone" : "Not Selected"
                }
                workflowId={item?.workflow_id}
              />
            );
          })}
      </Slider>
    </div>
  );
};
