import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { signup, verifyToken } from "../api/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignupPage = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companySize: 0,
    companyWebsite: "",
    aboutUs: "",
    useCase: "",
  });
  const [inviteCode, setInviteCode] = useState("");
  const [isAuth, setisAuth] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmVisibility = () => {
    setIsConfirmVisible(!isConfirmVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const obj = {
      name: formData?.name,
      email: formData?.email,
      password: formData?.password,
      role: "user",
      company_name: formData?.companyName || "",
      company_size: toString(formData?.companySize) || "",
      company_website: formData?.companyWebsite || "",
      reference: formData?.aboutUs,
      primary_usecase: formData?.useCase,
    };

    // Check if password and confirmPassword match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Clear error if passwords match
    setError("");

    signup(obj)
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
          navigate("/");
        } else {
          toast.error(res?.response?.data?.error_message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCode = (event) => {
    event.preventDefault();
    // setisAuth(true);

    verifyToken({ unique_code: inviteCode })
      .then((res) => {
        if (res.validated) {
          setisAuth(true);
          formData["name"] = res?.name;
          formData["email"] = res?.email;
        } else {
          toast.error(res?.response?.data?.error_message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error_message);
      });
  };

  return (
    <div>
      {" "}
      <div className="min-h-screen flex">
        {/* Left Section */}
        <div className="w-2/5 bg-gradient-to-br from-gray-800 to-blue-900 flex flex-col justify-center p-12 text-white">
          <div className="text-3xl font-bold mb-4">Normal Logistics</div>
          <p className="text-lg mb-2">
            Supercharge operations with an AI agent that works alongside your
            team
          </p>
          <p className="text-md">
            Browse the <span className="font-bold">Workflow Library</span> to
            automate tedious, repetitive manual tasks
          </p>
        </div>

        {/* Right Section */}
        {!isAuth ? (
          <div className="w-3/5 bg-white flex flex-col items-center justify-center p-12">
            <div className="w-full max-w-md">
              <div className="text-xl font-bold mb-4">Normal Logistics</div>

              <div className="w-full mb-4">
                <span className="font-medium text-sm text-[#212121]">
                  Enter your invite code below otherwise{" "}
                </span>
                <Link
                  to={"/login"}
                  className="text-primary text-sm hover:underline"
                >
                  Book a meeting now
                </Link>
              </div>
              <form onSubmit={handleCode}>
                <div className="mb-4">
                  <input
                    type="text"
                    name="inviteCode"
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                    placeholder="Enter Invite Code"
                    className="bg-input-grey w-full h-[48px] pt-2 pr-2 pb-2 pl-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm font-normal"
                    required
                    minLength={2}
                  />
                </div>

                {/* Sign */}
                <div className="mb-4">
                  <button
                    type="submit"
                    className="w-full bg-primary text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Sign-Up
                  </button>
                </div>

                {/* Break */}
                <div className="w-full h-[1px] bg-[#E5E5E5] mb-4"></div>

                <div className="text-center">
                  <span className="text-sm text-gray-600">
                    Already have an account?{" "}
                  </span>
                  <Link
                    to={"/login"}
                    className="text-primary text-sm hover:underline"
                  >
                    Sign in now
                  </Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="w-3/5 bg-white flex items-center justify-center p-12">
            <div className="w-full max-w-md">
              <div className="text-xl font-semibold mb-6">
                Welcome {formData?.name}!
              </div>
              <form onSubmit={handleSubmit}>
                {/* password */}
                <div className="mb-4 relative">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Enter password"
                    className="bg-input-grey w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    minLength={8}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <FaEyeSlash color="#4D4D4D" size={16} />
                    ) : (
                      <FaEye color="#4D4D4D" size={16} />
                    )}
                  </div>
                </div>
                {/* Confirm */}
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                <div className="mb-4 relative">
                  <input
                    type={isConfirmVisible ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm password"
                    className="bg-input-grey w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    minLength={8}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleConfirmVisibility}
                  >
                    {isConfirmVisible ? (
                      <FaEyeSlash color="#4D4D4D" size={16} />
                    ) : (
                      <FaEye color="#4D4D4D" size={16} />
                    )}
                  </div>
                </div>
                {/* company */}
                <div className="mb-4">
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    placeholder="Your company name"
                    className="bg-input-grey w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                    required
                  />
                </div>
                {/* about us */}
                <div className="mb-4">
                  <input
                    type="text"
                    name="aboutUs"
                    value={formData.aboutUs}
                    onChange={handleInputChange}
                    placeholder="Where did you hear about us? (optional)"
                    className="bg-input-grey w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  />
                </div>
                {/* use case */}
                <div className="mb-4">
                  <input
                    type="text"
                    name="useCase"
                    value={formData.useCase}
                    onChange={handleInputChange}
                    placeholder="What is your primary use case?"
                    className="bg-input-grey w-full h-12 pt-2 pr-2 pb-2 pl-4 text-sm font-normal border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                  />
                </div>

                {/* Sign */}
                <div className="mb-4">
                  <button
                    type="submit"
                    className="w-full bg-primary text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="absolute right-[25%] bottom-10">
        <p className="text-[#616161] test-sm font-normal text-center">
          © Normal Logistics 2024
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
