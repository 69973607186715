import React from "react";
import {
  logowhite,
  slack1,
  slack10,
  slack2,
  slack3,
  slack4,
  slack5,
  slack6,
  slack7,
  slack8,
  slack9,
} from "../assets/images";
import { GoDotFill } from "react-icons/go";

export const SlackDocs = () => {
  return (
    <div className="flex flex-col justify-center ">
      <div className="flex items-center justify-center h-20 w-full bg-[#212121] gap-6  ">
        <div className="">
          <img src={logowhite} alt="" className="" />
        </div>
        <div className="text-white text-3xl ">
          <span>How to integrate App with Slack</span>
        </div>
      </div>
      <div className="border-[1px] mx-auto border-white w-[60%] my-6 ">
        <div className="flex flex-col p-4 ">
          <div className="flex flex-col w-[90%] mx-auto">
            <span className="text-red-500 text-2xl text-start font-bold">
              NOTE:
            </span>
            <span className="text-white text-lg text-start mt-1">
              While creating an APP you need these field to integrate slack in
              normal bots so save these
            </span>
            <ol className="text-white text-md text-start mt-2">
              <li className="flex items-center ">
                <span className="mr-2">
                  <GoDotFill size={10} />
                </span>
                OAuth access token
              </li>
              <li className="flex items-center ">
                <span className="mr-2">
                  <GoDotFill size={10} />
                </span>
                Channel name (on which bot will escalate messages)
              </li>
              <li className="flex items-center ">
                <span className="mr-2">
                  <GoDotFill size={10} />
                </span>
                Bot user name (message will be displayed by user name)
              </li>
            </ol>

            <span className="text-white font-bold text-xl text-start mt-2 ">
              Configure your slack app:
            </span>
          </div>
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            To start, make sure you have a Slack account, you are a member of a
            Slack workspace that you have admin access to, and you’re signed in
            with this Slack account. If not, head over to slack.com and create a
            new account and workspace. Next, head over to 
            <a className="text-blue-500 underline">https://api.slack.com/app</a>
            , your Slack apps dashboard. It will look something like this:
          </span>
          <img src={slack1} alt="" className="h-[450px] w-[90%] mx-auto mt-2" />
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            Click on “Create an App”
          </span>
          <img src={slack2} alt="" className="h-[450px] w-[90%] mx-auto mt-2" />
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            Select “From scratch.” In the next screen, specify a name for your
            app, e.g., “Python Messenger,” and specify the workspace in which
            you want to develop this app (for your case, this workspace will be
            different).
          </span>
          <img src={slack3} alt="" className="h-[450px] w-[90%] mx-auto mt-2" />
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            Click on Create app. You now land on your newly created app
            homepage, where you can configure all the app’s settings, ranging
            from basic information to who else can make changes to this app and
            all features. Don’t be overwhelmed by this! We will only need to set
            a couple of settings.
          </span>
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            Head over to the “OAuth & Permissions” tab, scroll down to the
            Scopes card, and under Bot Token Scopes, click “Add an OAuth Scope”
            add these ‘chat:write’, ‘chat:write.customize’, ‘files:read’,
            ‘files:write’ scopes (you’ll need to click “Add an OAuth Scope four
            times in total).
          </span>
          <img src={slack4} alt="" className="h-[550px] w-[90%] mx-auto mt-2" />
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            Now you can scroll up to the “OAuth Tokens for Your Workspace”
            section, and click “Install to Workspace”:
          </span>
          <img src={slack5} alt="" className="h-[550px] w-[90%] mx-auto mt-2" />

          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            On the next screen that summarizes the scopes that you specified in
            the previous steps, click Allow.
          </span>
          <img src={slack6} alt="" className="h-[450px] w-[90%] mx-auto mt-2" />
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            You’ll see that a “Bot User OAuth Token” has been created for you:
          </span>
          <img src={slack7} alt="" className="h-[450px] w-[90%] mx-auto mt-2" />
          <div className="flex items-center gap-4 w-[90%] mx-auto mt-4">
            <div className="flex flex-col   ">
              <span className="text-white text-md text-start mt-2  ">
                You’ll need this Bot User OAuth Token in a later stage, so don’t
                close your browser window.
              </span>
              <span className="text-white text-md text-start mt-2 ">
                Open Slack and notice that a new app “Python Messenger” (or
                whatever you’ve called it) has appeared in your sidebar:
              </span>
            </div>
            <img src={slack8} alt="" />
          </div>
          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto ">
            The final configuration step is configuring the Slack channel that
            you’ll want to send the automated messages to. Create a new channel
            called #bot-updates. In the channel, type /app and select “Add apps
            to this channel”:
          </span>
          <img src={slack9} alt="" className="mt-2 w-[90%] mx-auto" />

          <span className="text-white text-md text-start mt-2 w-[90%] mx-auto">
            Next to “Python messenger”, click on “Add”. You’ll see that the
            “Python Messenger” app has joined the channel.
          </span>
          <img src={slack10} alt="" className="mt-2 w-[90%] mx-auto" />
        </div>
      </div>
    </div>
  );
};
