/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  getKnowledgeBase,
  getKnowledgeBaseByKbId,
} from "../../api/knowledgebase";
import { KBNameCard } from "./components/KBNameCard";
import { toast } from "react-toastify";
export const WorkflowKB = () => {
  const [kbConfig, setKbConfig] = useState([]);
  const [kbConfigNames, setKbConfigNames] = useState([]);
  const [mergedObj, setMergedObj] = useState([]);
  const [configs, setConfigs] = useState([]);
  const isInitialRender = useRef(true);

  const fetchKb = async () => {
    try {
      const kb = await getKnowledgeBase();

      if (kb) {
        const parsedKb = kb?.data?.map((item) => ({
          ...item,
          config_json: JSON.parse(item.config_json),
        }));
        setKbConfig(parsedKb);
        const configNamesPromises = kb?.data?.map(
          async ({ knowledge_base_integration_id }) => {
            const names = await getKnowledgeBaseByKbId(
              knowledge_base_integration_id
            );
            return names;
          }
        );
        const configNames = await Promise.all(configNamesPromises);
        setKbConfigNames(configNames);
        toast.success("KnowledgeBase is Connected", { toastId: "1" });
        toast.error(kb?.data?.error_message, { toastId: "3" });
      }
    } catch (error) {
      console.error("Error fetching knowledge base:", error);
      toast.error("Failed to fetch knowledge base details.", { toastId: "2" });
    }
  };
  const customMerge = () => {
    const mergedConfig = kbConfig.map((kb) => {
      const matchedObj = kbConfigNames.find(
        (obj) => obj.id === kb.knowledge_base_integration_id
      );

      if (matchedObj) {
        return {
          ...kb,
          name: matchedObj.name,
        };
      }
      return kb;
    });

    setMergedObj(mergedConfig);
  };
  useEffect(() => {
    if (kbConfig.length > 0 && kbConfigNames.length > 0) {
      customMerge();
    }
  }, [kbConfig, kbConfigNames]);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      fetchKb();
    }
  }, []);

  const handleUpdate = (id, updatedData) => {
    setConfigs((prevConfigs) =>
      prevConfigs?.map((config) =>
        config?.id === id
          ? { ...config, config_json: JSON.stringify(updatedData) }
          : config
      )
    );
  };

  return (
    <div className="bg-[#101010] text-white min-h-screen p-8 space-y-4 customScroll">
      <h2 className="font-bold">Enabled Knowledge Bases</h2>
      <div className="w-full flex justify-start items-center flex-wrap gap-4">
        {mergedObj?.length > 0 &&
          mergedObj?.map((obj, index) => (
            <KBNameCard key={index} config={obj} onUpdate={handleUpdate} />
          ))}
      </div>
      <h2 className="font-bold">Add Knowledge Base</h2>
      <button className="w-[12rem] rounded-md px-3 flex min-h-[5rem] justify-center items-center min-w-[5rem] border border-dark-7 bg-[#222227]">
        +Add Knowledge Base
      </button>
    </div>
  );
};
