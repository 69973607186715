import React, { useState } from "react";
import { selectUser } from "../redux";
import { useSelector } from "react-redux";
import { getFirstLetter } from "../utils";

export const ProfilePage = () => {
  const userDetail = useSelector(selectUser);
  const [profileData, setProfileData] = useState(userDetail);
  const [isEdit, setIsEdit] = useState(false);

  // hamdle data
  const handleDataChange = (e) => {
    setProfileData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className="bg-[#101010] text-white min-h-screen p-8 space-y-4 customScroll">
      <div className="w-full flex flex-col justify-center items-center bg-agent-card-bg p-5 rounded-md">
        <div className="w-full flex flex-col justify-center items-center space-y-4">
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-center items-center gap-4">
              <div className="avatar">
                <div className="h-14 w-14 flex justify-center items-center text-2xl border-[#00897b] hover:animate-bounce rounded-full bg-[#00897b] text-white text-center cursor-pointer">
                  {getFirstLetter(userDetail?.name)}
                </div>
              </div>
              <div>
                <h4 className="font-bold">{userDetail?.name}</h4>
                <p className="font-light text-dark-7">
                  {userDetail?.email || "johndoe@gmail.com"}
                </p>
              </div>
            </div>
            {/* <div>
              <button
                className="btn bg-primary px-5 rounded-md py-1"
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? "Update" : "Edit"}
              </button>
            </div> */}
          </div>

          <div className="w-full flex justify-between items-center gap-4">
            <label className="form-control w-full max-w-full">
              <div className="label mb-1">
                <span className="label-text">First Name</span>
              </div>
              <input
                readOnly={!isEdit}
                name="firstName"
                type="text"
                placeholder="name"
                className="input input-sm input-bordered w-full rounded-md bg-dark-2"
                onChange={(e) => handleDataChange(e)}
                value={profileData?.name}
              />
            </label>
          </div>
          <div className="w-full flex justify-between items-center gap-4">
            <label className="form-control w-full max-w-full">
              <div className="label mb-1">
                <span className="label-text">Email</span>
              </div>
              <input
                readOnly
                name="email"
                type="email"
                placeholder="johndoe@gmail.com"
                className="input input-sm input-bordered w-full rounded-md bg-dark-2"
                onChange={(e) => handleDataChange(e)}
                value={profileData?.email}
              />
            </label>
          </div>
          <div className="w-full flex justify-between items-center gap-4">
            <label className="form-control w-full max-w-full">
              <div className="label mb-1">
                <span className="label-text">Company Name</span>
              </div>
              <input
                name="company_size"
                type="text"
                className="input input-sm text-white input-bordered w-full rounded-md bg-dark-2"
                value={profileData?.company_name}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
