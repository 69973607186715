import React, { useEffect, useState } from "react";
import { AxeIcon } from "../../assets/icons";
import Modal from "./Modal";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { getWorkflow } from "../../redux";
import "./RadioCard.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  checkKbsConfig,
  getKnowledgeBase,
  getKnowledgeBaseByKbId,
  kbAddConfig,
} from "../../api/knowledgebase";
import { refreshToken } from "../../api/user";

export const Step4 = ({ prevStep, nextStep, formData, setFormData }) => {
  const [selectedKBOption, setSelectedKbOption] = useState("GDrive");
  const [toggle, setToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [kbConfig, setKbConfig] = useState([]);
  const [kbConfigNames, setKbConfigNames] = useState([]);
  const [mergedObj, setMergedObj] = useState([]);
  const workflowFlags = useSelector(getWorkflow);
  const [isDisable, setIsDisable] = useState(workflowFlags?.kb_google_drive);
  const kbLabels = {
    GDrive: "Google Drive",
    kb_other_docs: "Other Docs",
    kb_tms_speedx: "TMS SpeedX",
    kb_tms_ulala: "TMS Ulala",
  };

  const user = localStorage.getItem("userDetails");
  const parse = JSON.parse(user);

  const handleTestConnection = async () => {
    toast.warning("Creating your KnowledgeBase.", { toastId: "4" });
    const addconfig = {
      google_drive_url:
        "https://drive.google.com/drive/folders/1gy0oW5myYvC7eaJYfk-P9gyqMjHrs31u",
      google_creds_path:
        "google_creds_path:my-drive/rfc-knowdlgebase/sample-data",
      client_id: parse?.client_id,
      knowledge_base_integration_id: 1,
      knowledge_base_integration_type: "GDrive",
    };
    await refreshToken()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
        }
      })
      .catch((err) => console.log(err));
    const test = await checkKbsConfig(addconfig);
    if (test.status === 200) {
      toast.success(test?.data?.message, { toastId: "6" });
    }

    await kbAddConfig(addconfig)
      .then((res) => {
        if (res.status === 200) {
          setIsConnected(true);
          fetchKb();
        }
        if (res?.response?.status === 400) {
          toast.error(res?.response?.data?.error_message, { toastId: "5" });
        }
      })
      .catch((err) => console.log(err));
    setIsModalOpen(true);
  };
  const fetchKb = async () => {
    try {
      const kb = await getKnowledgeBase();
      if (kb?.status !== 200) {
        handleTestConnection();
      } else {
        if (kb) {
          const parsedKb = kb?.data?.map((item) => ({
            ...item,
            config_json: JSON.parse(item.config_json),
          }));
          setKbConfig(parsedKb);
          const configNamesPromises = kb?.data?.map(
            async ({ knowledge_base_integration_id }) => {
              const names = await getKnowledgeBaseByKbId(
                knowledge_base_integration_id
              );
              return names;
            }
          );
          const configNames = await Promise.all(configNamesPromises);
          setKbConfigNames(configNames);
          setIsConnected(true);
          toast.success("KnowledgeBase is Connected", { toastId: "1" });
        } else {
          toast.error(kb?.data?.error_message, { toastId: "3" });
        }
      }
    } catch (error) {
      console.error("Error fetching knowledge base:", error);
      toast.error("Failed to fetch knowledge base details.", { toastId: "2" });
    }
  };

  const handleNextStep = () => {
    nextStep();
  };
  const customMerge = () => {
    const mergedConfig = kbConfig.map((kb) => {
      const matchedObj = kbConfigNames.find(
        (obj) => obj.id === kb.knowledge_base_integration_id
      );

      if (matchedObj) {
        return {
          ...kb,
          name: matchedObj.name,
        };
      }
      return kb;
    });

    setMergedObj(mergedConfig);
  };
  useEffect(() => {
    if (kbConfig.length > 0 && kbConfigNames.length > 0) {
      customMerge();
    }
  }, [kbConfig, kbConfigNames]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isDisable) {
        fetchKb();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const data = mergedObj?.map((obj) => obj?.id);
    setFormData({
      ...formData,
      knowledge_base_integration_config_ids: String(mergedObj[0]?.id),
    });
  }, [mergedObj]);

  const setId = (value) => {
    setSelectedKbOption(value);
    setToggle(!toggle);
  };
  return (
    <div className="w-full bg-[#212121] px-16 py-6 flex flex-col gap-6 rounded-lg">
      {isModalOpen && <Modal onClose={closeModal} isConnected={isConnected} />}{" "}
      {/* Render modal if open */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-4">
          <div className="w-6 h-6 mt-1 rounded-md bg-white text-center text-black">
            4
          </div>
          <p className="text-[32px] font-semibold text-white">
            Set up your knowledge bases
          </p>
        </div>

        <div className="flex items-center gap-2">
          <AxeIcon />
          <div className="bg-[#E9F2F9] px-4 py-2 text-sm rounded-lg text-[#324769]">
            Uploading files gives agents context about your company & policies.
            Don’t worry you can always add more later. Our data engine will read
            your files and understand how to use each data table on its own!
          </div>
        </div>
        <div className="w-full bg-[#101010] h-[1px]"></div>

        <div className="flex flex-col mt-6 gap-4">
          <h3 className="text-[20px] text-[#EEEEEE]">
            Add your knowledge base
          </h3>
          <div className="flex gap-4">
            {Object.keys(kbLabels).map((key) => (
              <button
                key={key}
                className={`bg-[#1A1B20] border-[1.81px] text-white w-36  ${
                  isDisable && key === "GDrive" ? "border-blue-600" : ""
                } py-4 px-2 rounded ${"border-[#2a2a2b]"} ${
                  key === "GDrive" ? "cursor-pointer" : "cursor-not-allowed"
                } `}
                onClick={() => setId(key)}
                disabled={key !== "GDrive"}
              >
                {kbLabels[key]}
              </button>
            ))}
          </div>
        </div>
        {mergedObj?.map((obj, index) => (
          <>
            {selectedKBOption === "GDrive" && toggle && isDisable && (
              <div>
                <div className="flex flex-col  gap-4 justify-start items-start border text-white p-4">
                  <label htmlFor="">URL</label>
                  <input
                    type="text"
                    className="input border input-lg rounded-lg w-full bg-transparent text-white"
                    disabled
                    value={obj?.config_json?.google_drive_url}
                  />
                  <label htmlFor="">Credentials Path</label>
                  <input
                    type="text"
                    className="input border input-lg rounded-lg w-full bg-transparent text-white"
                    value={obj?.config_json?.google_creds_path}
                    disabled={obj?.config_json?.google_creds_path !== ""}
                  />
                </div>
                <p className="mt-2 text-blue-600 italic ">
                  KnowledgeBase already configured
                </p>
              </div>
            )}
          </>
        ))}
        {selectedKBOption === "GDrive" && !isDisable && (
          <>
            <div>
              <div className="flex flex-col  gap-4 justify-start items-start border text-white p-4">
                <label htmlFor="">URL</label>
                <input
                  type="text"
                  className="input border input-lg rounded-lg w-full bg-transparent text-white"
                  value={"This/is/dummy/url"}
                />
                <label htmlFor="">Credentials Path</label>
                <input
                  type="text"
                  className="input border input-lg rounded-lg w-full bg-transparent text-white"
                  value={"//dummy-path"}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {mergedObj?.find(
        (obj) => obj?.name === selectedKBOption && mergedObj?.config_json
      ) && (
        <button
          onClick={handleTestConnection}
          className="mt-4 bg-[#007AFF] text-white px-4 py-2 rounded"
        >
          Test Connection
        </button>
      )}
      {/* Navigation Buttons */}
      <div className="flex justify-end gap-6">
        <button
          onClick={prevStep}
          className="text-white font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline"
        >
          <FaArrowLeftLong size={30} />
        </button>
        <button
          disabled={!isConnected}
          onClick={handleNextStep}
          className="w-[13%] px-4 py-2 text-white rounded-md bg-[#007AFF] hover:bg-blue-600 focus:outline-none"
        >
          {isConnected ? "Next" : "Connecting"}
        </button>
      </div>
    </div>
  );
};
