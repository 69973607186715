import React from "react";

export default function DashboardIcon({ color }) {
  return (
    <svg
      width="27"
      height="18"
      viewBox="0 0 27 18"
      fill={color || "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.0797 14.1182C21.0151 14.1182 23.4169 11.7355 23.4169 8.80002C23.4169 5.86453 21.0342 3.48183 18.0797 3.48183H8.94916C6.01367 3.48183 3.63098 5.86453 3.63098 8.80002C3.63098 11.7355 8.21226 8.80002 11.1477 8.80002L18.0797 14.1182ZM8.73947 17.0156C4.18377 17.0156 0.504883 13.3367 0.504883 8.80002C0.504883 4.26336 4.18377 0.584473 8.73947 0.584473H18.2893C22.826 0.584473 26.5049 4.26336 26.5049 8.80002C26.5049 13.3367 22.826 17.0156 18.2893 17.0156H8.73947Z" />
    </svg>
  );
}
