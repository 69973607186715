import axios from "axios";
import config from "./config";

const BASE_URL = config.BASE_URL;

export const login = (values) => {
  return axios
    .post(`${BASE_URL}/users/signin`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const signup = (values) => {
  return axios
    .post(`${BASE_URL}/users/signup`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const verifyToken = (values) => {
  return axios
    .post(`${BASE_URL}/client_invite/validate-invite-token`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const refreshToken = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(
      `${BASE_URL}/users/refresh`,
      { refresh_token: parse?.refresh_token },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getUserById = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/users/get-user-by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

// 
export const createOTP = (values) => {
  return axios
    .post(`${BASE_URL}/users/generate-otp-and-send-email`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const validateOTP = (values) => {
  return axios
    .post(`${BASE_URL}/users/validate-otp`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const resetPassword = (values) => {
  return axios
    .post(`${BASE_URL}/users/reset-password`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};