import React, { useState, useEffect } from "react";
import "./Modal.css";

const DeployModal = ({
  onClose,
  agentName,
  agentDescription,
  prompt,
  deploymentConnection,
}) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    `Setting Deployment Agent...: ${agentName}`,
    `Agent Task...: ${agentDescription}`,
    `Prompt: ${prompt?.substring(0, 25)}`,
    `Connecting to: ${deploymentConnection}`,
    "Connection Successful!",
  ];

  useEffect(() => {
    if (messageIndex < messages.length - 1) {
      const timeout = setTimeout(() => {
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 500);

      return () => clearTimeout(timeout);
    } else {
      const closeTimeout = setTimeout(onClose, 500);
      return () => clearTimeout(closeTimeout);
    }
  }, [messageIndex, onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="loader"></div>
        <p className="text-white text-xl mt-4">{messages[messageIndex]}</p>
      </div>
    </div>
  );
};

export default DeployModal;
