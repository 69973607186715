import axios from "axios";
import config from "./config";

const BASE_URL = config.BASE_URL;
export const checkKbsConfig = (obj) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(`${BASE_URL}/kb-integration-config/check-connection`, obj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      console.log(err);
      return err?.response?.data;
    });
};
export const kbAddConfig = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(`${BASE_URL}/kb-integration-config/add-config`, values, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getKb = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/kb-integrations/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getKnowledgeBase = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/kb-integration-config/by-client-id/${parse?.client_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((res) => {
      return res.response;
    });
};

export const getKnowledgeBaseID = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/kb-integrations`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};

export const getKnowledgeBaseByKbId = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/kb-integrations/by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};
