import React, { useEffect, useRef, useState } from "react";
import { NameCard, RadioCard } from "./components";
import { getActionAndTools } from "../../api/action-and-tools";
import { toast } from "react-toastify";

export const WorkFlowActionAndTools = () => {
  const [configs, setConfigs] = useState([]);
  const isInitialRender = useRef(true);

  const fetchActionAndTools = async () => {
    const res = await getActionAndTools();
    if (res?.status === 200) {
      toast.success("Action and Tools fetched");
    } else {
      toast.error(res.error_message);
    }
    setConfigs(res.data);
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      fetchActionAndTools();
    }
  }, []);

  return (
    <div className="bg-[#101010] text-white min-h-screen p-8 space-y-4 customScroll">
      <h2 className="font-bold">Enabled Action and Tools</h2>
      {configs?.map((config_json, index) => (
        <NameCard config={config_json} key={index} />
      ))}
      <h2 className="font-bold">Add Action and Tools</h2>
      <div className="w-full flex justify-start items-center flex-wrap gap-4 cursor-pointer">
        <RadioCard text={"+Add action and tools"} />
      </div>
    </div>
  );
};
