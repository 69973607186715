import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Onboarding from "./pages/Onboarding";
import Dashboard from "./pages/Dashboard";
import SignupPage from "./pages/SignupPage";
import Layout from "./pages/Layout";
import Workflow from "./components/workflow/Workflow";
import { WorkflowKB } from "./components/workflow/WorkflowKB";
import { WorkFlowActionAndTools } from "./components/workflow/WorkFlowActionAndTools";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import AgentsDetailPage from "./pages/AgentsDetailPage";
import { ProfilePage } from "./pages/ProfilePage";
import { MultiStepForm } from "./components/setupNewAgent/MultiStepForm";
import { WorkflowDeploymentPage } from "./components/workflow/WorkflowDeploymentPage";
import { ForgetPassword } from "./pages/ForgetPassword";
import { SlackDocs } from "./pages/SlackDocs";
import "react-toastify/dist/ReactToastify.css";
import { getAgentCurrentWeekTimeSaveReport, getAgentsById } from "./api/agent";
import {
  setAgentCurrentWeekSavedTime,
  setAgents,
  setAllWorkflows,
  setUser,
  setWorkflowCategories,
} from "./redux";
import { getUserById, refreshToken } from "./api/user";
import { getWorkflowCategories, getWorkflows } from "./api/workflow";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function App() {
  const [clientId, setClientId] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    const user = localStorage.getItem("userDetails");
    const parse = JSON.parse(user);

    if (parse !== null) {
      refreshToken()
        .then((res) => {
          if (res?.status === 200) {
            localStorage.setItem("userDetails", JSON.stringify(res?.data));
          }
        })
        .catch((err) => console.log(err));
      getAgentCurrentWeekTimeSaveReport(parse?.client_id)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAgentCurrentWeekSavedTime(res?.data));
          }
        })
        .catch((err) => console.log(err));
      getAgentsById(parse?.client_id)
        .then((res) => {
          if (res?.status === 200) {
            dispatch(setAgents(res?.data));
          } else if (res?.response?.status === 400) {
            dispatch(setAgents([]));
          } else {
            toast.error(res?.response?.data?.error_message);
          }
        })
        .catch((err) => console.log("agent", err));

      getWorkflowCategories()
        .then((res) => {
          dispatch(setWorkflowCategories(res));
        })
        .catch((err) => console.log(err));

      getWorkflows()
        .then((res) => {
          dispatch(setAllWorkflows(res));
        })
        .catch((err) => console.log(err));

      getUserById(parse?.user_id)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setUser(res?.data));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [clientId]);
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<LoginPage setClientId={setClientId} />}
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/onboard" element={<Onboarding />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/slack-integration-docx"
            element={
              <PrivateRoute>
                <SlackDocs />
              </PrivateRoute>
            }
          />
          <Route
            path="/deployments"
            element={
              <PrivateRoute>
                <Layout>
                  <WorkflowDeploymentPage />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/action-and-tools"
            element={
              <PrivateRoute>
                <Layout>
                  <WorkFlowActionAndTools />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/workflow"
            element={
              <PrivateRoute>
                <Layout>
                  <Workflow />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/agent-detail"
            element={
              <PrivateRoute>
                <Layout>
                  <AgentsDetailPage />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/setup-new-agent"
            element={
              <PrivateRoute>
                <Layout>
                  <MultiStepForm />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/knowledgebase"
            element={
              <PrivateRoute>
                <Layout>
                  <WorkflowKB />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/user-profile"
            element={
              <PrivateRoute>
                {/* <Layout showAgents={false}> */}
                <Layout>
                  <ProfilePage />
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
