import React from "react";

const ConfirmationModal = ({
  onClose,
  onConfirm,
  deploymentType,
  deploymentConnection,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#212121] w-[450px] rounded-lg p-8 text-white shadow-lg">
        <h2 className="text-[24px] font-semibold mb-4">Confirm Deployment</h2>
        <p className="text-[16px] text-[#BDBDBD] mb-6">
          Are you sure you want to deploy your agent with the following details?
        </p>

        <div className="mb-6">
          <p className="text-[18px] font-bold text-white">Deployment Type:</p>
          <p className="text-[16px] text-[#EEEEEE] mb-4">{deploymentType}</p>

          <p className="text-[18px] font-bold text-white">
            Deployment Connection:
          </p>
          <p className="text-[16px] text-[#EEEEEE]">{deploymentConnection}</p>
        </div>

        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-[#616161] hover:bg-[#757575] rounded focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-[#007AFF] hover:bg-blue-600 rounded focus:outline-none"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
