import React, { useRef } from "react";
import { CloudIcon } from "../../assets/icons";

const FileUpload = ({ formData, setFormData, handleInputChange }) => {
  const fileInputRef = useRef(null);

  // Function to trigger file input click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        file: file, // Update the state with the selected file
      });
    }
  };
  return (
    <div className="w-[80%] border-2 border-dashed border-gray-300 rounded-lg p-4 flex items-center justify-between">
      {/* Left Section */}
      <div className="flex items-center">
        <CloudIcon />
        <div>
          <p className="text-gray-800 text-sm font-medium">
            Select a file or drag and drop here
          </p>
          <p className="text-gray-500 text-sm opacity-40">
            JPG, PNG or PDF, file size no more than 10MB
          </p>
        </div>
      </div>

      {/* Right Section */}
      <button
        onClick={handleButtonClick}
        className="text-[#0F91D2] border border-primary rounded-md px-2 py-2 text-xs font-medium"
      >
        SELECT FILE
      </button>
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};

export default FileUpload;
