import React from "react";

export const LogoBlack = () => {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 40 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0381 21.6818C31.5542 21.6818 35.2493 18.0161 35.2493 13.5C35.2493 8.98387 31.5836 5.31818 27.0381 5.31818H12.9912C8.47505 5.31818 4.80939 8.98387 4.80939 13.5C4.80939 18.0161 11.8575 13.5 16.3736 13.5L27.0381 21.6818ZM12.6686 26.1393C5.65983 26.1393 0 20.4795 0 13.5C0 6.52053 5.65983 0.860703 12.6686 0.860703H27.3607C34.3402 0.860703 40 6.52053 40 13.5C40 20.4795 34.3402 26.1393 27.3607 26.1393H12.6686Z"
        fill="#212121"
      />
    </svg>
  );
};
