import "react-quill/dist/quill.snow.css";
import "../setupNewAgent/CustomQuillEditor.css";

const EscalationModal = ({ data, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-black text-white rounded-lg w-3/4 max-w-[915px]">
        <div className="flex justify-between items-center px-6 py-4">
          <h2 className="text-lg font-semibold">Agent Transcript</h2>
          <div className="flex gap-6">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
          </div>
        </div>

        <div className="px-6">
          <div className="w-full flex items-center gap-2 mb-2">
            <p className="text-[18px] font-bold text-white">Agent Name:</p>
            <p className="text-[16px] text-[#EEEEEE]">{data?.agent_name}</p>
          </div>

          <div className="w-full flex items-center gap-2 mb-2">
            <p className="text-[18px] font-bold text-white">Task Summary:</p>
            <p className="text-[16px] text-[#EEEEEE]">{data?.task_summary}</p>
          </div>

          <div className="w-full flex items-center gap-2 mb-2">
            <p className="text-[18px] font-bold text-white">
              Escalation Reason:
            </p>
            <p className="text-[16px] text-[#EEEEEE]">
              {data?.escalation_reason}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EscalationModal;
