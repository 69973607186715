import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LogoBlack } from "../assets/icons";
import { createOTP, validateOTP, resetPassword } from "../api/user";

export const ForgetPassword = () => {
  const [step, setStep] = useState(1);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await createOTP({ email: formData.email });
      if (response?.status === 200) {
        setIsOtpModalOpen(true);
        setStep(2);
        toast.success("OTP sent successfully to your email.", {
          autoClose: 3000,
        });
      } else {
        throw new Error(
          "Failed to send OTP. Please check your email and try again."
        );
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong. Please try again.", {
        autoClose: 3000,
      });
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await validateOTP({
        email: formData.email,
        otp: formData.otp,
      });

      if (response?.status === 200) {
        setIsOtpModalOpen(false);
        setStep(3);
        toast.success("OTP verified successfully.", {
          autoClose: 3000,
        });
      } else {
        throw new Error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong. Please try again.", {
        autoClose: 3000,
      });
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match. Please try again.", {
        autoClose: 3000,
      });
      return;
    }

    try {
      const response = await resetPassword({
        email: formData.email,
        updated_password: formData.password,
      });

      if (response?.status === 200) {
        toast.success("Password updated successfully", {
          autoClose: 5000,
        });
        navigate("/login");
      } else {
        throw new Error("Failed to update password. Please try again.");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong. Please try again.", {
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="min-h-screen flex">
      <div className="w-2/5 bg-gradient-to-br from-gray-800 to-blue-900 flex flex-col justify-center p-12 text-white">
        <div className="text-3xl font-bold mb-4">Normal Logistics</div>
        <p className="text-lg mb-2">
          Supercharge operations with an AI agent that works alongside your team
        </p>
        <p className="text-md">
          Browse the <span className="font-bold">Workflow Library</span> to
          automate tedious, repetitive manual tasks
        </p>
      </div>
      <div className="w-3/5 bg-white flex items-center justify-center p-12">
        <div className="w-full max-w-md">
          <div className="flex gap-4 items-center mb-6">
            <div>
              <LogoBlack />
            </div>
            <div className="text-xl font-bold ">Normal Logistics</div>
          </div>

          {step === 1 && (
            <form onSubmit={handleSendOtp}>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="bg-input-grey w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-md"
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Send OTP
                </button>
              </div>
            </form>
          )}

          {step === 3 && (
            <form onSubmit={handlePasswordUpdate}>
              <div className="mb-4 relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  className="bg-input-grey w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <FaEyeSlash color="#4D4D4D" size={16} />
                  ) : (
                    <FaEye color="#4D4D4D" size={16} />
                  )}
                </div>
              </div>
              <div className="mb-4 relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                  className="bg-input-grey w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Update
                </button>
              </div>
            </form>
          )}

          {/* Modal for OTP Verification */}
          {isOtpModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-bold mb-4">Enter OTP</h2>
                <input
                  type="text"
                  name="otp"
                  value={formData.otp}
                  onChange={handleInputChange}
                  placeholder="Enter OTP"
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleVerifyOtp}
                    className="bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};
