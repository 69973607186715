import axios from "axios";
import config from "./config";

const BASE_URL = config.BASE_URL;

export const getAllAgents = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/agents`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};

export const getAgentsById = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/agents/get-by-client-id/${parse?.client_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const createAgent = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(`${BASE_URL}/agents/add-agent`, values, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const updateAgent = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .put(`${BASE_URL}/agents/update-agent`, values, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const removeAgent = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .delete(`${BASE_URL}/agents/by-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => console.log(err));
};

export const slackToolConfig = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(`${BASE_URL}/action-and-tools-config/add-config`, values, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const checkKbConnection = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .post(`${BASE_URL}/kb-integration-config/check-connection`, values, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchAgentTasks = () => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/agent-task`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res?.data) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getAgentTasksById = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/agent-task/get-by-agent-id/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};
export const getAgentCurrentWeekTimeSaveReport = (id) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .get(`${BASE_URL}/overview-report/get-current-week-time-saved/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};

export const updateAgentStatus = (values) => {
  const token = localStorage.getItem("userDetails");
  const parse = JSON.parse(token);
  return axios
    .patch(`${BASE_URL}/agents/deploy-undeploy-agent`, values, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + parse.access_token,
      },
    })
    .then((res) => {
      if (res) return res;
    })
    .catch((err) => {
      return err;
    });
};
