import React, { useState } from "react";
import KnowledgeBase from "../components/onboarding/KnowledgeBase";
import ChooseTools from "../components/onboarding/ChooseTools";

export default function Onboarding() {
  const [pageNumber, setPageNumber] = useState(1);
  const [activeTool, setActiveTool] = useState(null);
  const [activeKB, setActiveKB] = useState(null);
  const [formData, setFormData] = useState({
    file: "",
    notion: "",
    kbWebsiteUrl: "",
    googleSheet: "",
    deployWebsiteUrl: "",
    deployPhone: "",
    deployEmail: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      {pageNumber === 1 ? (
        <ChooseTools
          setPageNumber={setPageNumber}
          activeTool={activeTool}
          setActiveTool={setActiveTool}
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />
      ) : pageNumber === 2 ? (
        <KnowledgeBase
          setPageNumber={setPageNumber}
          activeKB={activeKB}
          setActiveKB={setActiveKB}
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />
      ) : (
        // <Deployment
        //   setPageNumber={setPageNumber}
        //   deploymentPlatform={deploymentPlatform}
        //   setDeploymentPlatform={setDeploymentPlatform}
        //   formData={formData}
        //   setFormData={setFormData}
        //   handleInputChange={handleInputChange}
        // />
        <></>
      )}
    </>
  );
}
