import React, { useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { FaEye } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import EscalationModal from "../agentDetail/EscalationDetail";

export default function AgentTable({
  data,
  total_agent_escalations,
  total_escalations_percentage,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const tasksPerPage = 5;

  // Calculate the range of tasks to display
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = data?.slice(indexOfFirstTask, indexOfLastTask);

  const totalPages = Math.ceil(data?.length / tasksPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCallDetails = (detail) => {
    setSelectedTask(detail);
  };
  return (
    <div className="relative overflow-x-auto bg-gray-900 border-gray-800 rounded-md">
      <div className=" flex justify-between ">
        <div className="flex flex-col justify-between px-6 py-2">
          <div className="text-sm text-[#AEB9E1] font-medium">
            Agent Task Escalations
          </div>
          <div className="flex items-center gap-2">
            <div className="text-xl text-white">{total_agent_escalations}</div>
            <div className="bg-[#22c55e33] h-[18px] w-14 text-[#14CA74] justify-center items-center gap-1 text-[11px] flex">
              {total_escalations_percentage} %
              <GoArrowUpRight />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <table className="w-full bg-gray-900 p-6 text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-[#87888C] uppercase">
          <tr>
            <th scope="col" className="px-6 py-3 w-3/12">
              Agent Name
            </th>
            <th scope="col" className="px-6 py-3 w-3/12">
              Task ID
            </th>
            <th scope="col" className="px-6 py-3 w-3/12">
              Task Name
            </th>
            <th scope="col" className="px-6 py-3 w-6/12 text-center">
              Action
            </th>
          </tr>
        </thead>

        <tbody>
          {currentTasks?.map((task, index) => (
            <tr key={index} className="border-t border-gray-700 h-[30px]">
              <th
                scope="row"
                className={`px-6 py-2 font-medium text-white whitespace-nowrap w-3/12`}
              >
                {task?.agent_name}
              </th>
              <th
                scope="row"
                className={`px-6 py-2 font-medium text-white whitespace-nowrap w-3/12`}
              >
                {task?.task_id}
              </th>
              <td className="px-6 py-2 text-white w-3/12">{task.task_name}</td>
              <td className="px-6 py-2 text-white w-6/12 text-center">
                <button
                  onClick={() => {
                    handleCallDetails(task);
                    setIsModalOpen(true);
                  }}
                  className={` font-normal text-sm px-4 rounded-sm text-green-300`}
                >
                  <FaEye />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <div className="flex justify-end items-center text-sm text-gray-400 mt-2">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`px-2 py-1 rounded ${
              currentPage === 1 ? "text-white cursor-not-allowed" : "text-white"
            }`}
          >
            <MdKeyboardArrowLeft size={16} />
          </button>
          {/* Count */}
          <div className="text-white text-xs opacity-85">{`${
            indexOfFirstTask + 1
          } - ${indexOfLastTask} of ${data?.length}`}</div>
          {/* count end */}
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-2 py-1 rounded ${
              currentPage === totalPages
                ? "text-white cursor-not-allowed"
                : "text-white"
            }`}
          >
            <MdKeyboardArrowRight size={16} />
          </button>
        </div>
      </div>
      <EscalationModal
        data={selectedTask}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
