import React from "react";

export default function BooksIcon({ color }) {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill={color || "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_159_2127)">
        <path d="M10.5 12.3999H14.5V10.3999H10.5V12.3999ZM10.5 9.3999H18.5V7.3999H10.5V9.3999ZM10.5 6.3999H18.5V4.3999H10.5V6.3999ZM8.5 16.3999C7.95 16.3999 7.47917 16.2041 7.0875 15.8124C6.69583 15.4207 6.5 14.9499 6.5 14.3999V2.3999C6.5 1.8499 6.69583 1.37907 7.0875 0.987402C7.47917 0.595736 7.95 0.399902 8.5 0.399902H20.5C21.05 0.399902 21.5208 0.595736 21.9125 0.987402C22.3042 1.37907 22.5 1.8499 22.5 2.3999V14.3999C22.5 14.9499 22.3042 15.4207 21.9125 15.8124C21.5208 16.2041 21.05 16.3999 20.5 16.3999H8.5ZM8.5 14.3999H20.5V2.3999H8.5V14.3999ZM4.5 20.3999C3.95 20.3999 3.47917 20.2041 3.0875 19.8124C2.69583 19.4207 2.5 18.9499 2.5 18.3999V4.3999H4.5V18.3999H18.5V20.3999H4.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_159_2127">
          <rect
            width="24"
            height="20"
            fill="white"
            transform="translate(0.5 0.399902)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
