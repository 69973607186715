import React from "react";
import { WorkflowTemplates } from "./WorkflowTemplates";
import { SimpleAgentCarousel } from "./components";
import { useSelector } from "react-redux";
import { getAgents } from "../../redux";

const Workflow = () => {
  const agents = useSelector(getAgents);
  return (
    <div className="bg-black text-white min-h-screen p-8 space-y-8 customScroll">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <h1 className="text-2xl font-semibold">Enabled Workflows</h1>
        </div>
      </div>
      {/* Carousel */}
      <div className="w-full ">
        <SimpleAgentCarousel agents={agents || []} />
      </div>

      {/* Templates */}
      <WorkflowTemplates />
    </div>
  );
};

export default Workflow;
