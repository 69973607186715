import React, { useState, useEffect } from "react";
import { getWorkflowCategories, getWorkflows } from "../../api/workflow";
import { BrowseAgentCard } from "./BrowseAgentCard";
import { useDispatch } from "react-redux";
import {
  setAllWorkflows,
  setWorkflow,
  setWorkflowCategories,
} from "../../redux";
import { useNavigate } from "react-router-dom";

export const WorkflowTemplates = () => {
  const [activeTab, setActiveTab] = useState(null); // Store category ID instead of category name
  const [categories, setCategories] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleAgentSelect = (categoryId) => {
    setActiveTab(categoryId);
    setSelectedCardId(null);
  };

  useEffect(() => {
    getWorkflowCategories()
      .then((res) => {
        if (res && res?.length > 0) {
          setCategories(res);
          dispatch(setWorkflowCategories(res));
          setActiveTab(res[0]?.id);
        } else {
          console.error("No categories returned");
        }
      })
      .catch((err) => console.error("Error fetching categories:", err));

    getWorkflows()
      .then((res) => {
        if (res && res?.length > 0) {
          setWorkflows(res);
          dispatch(setAllWorkflows(res));
        } else {
          console.error("No workflows returned");
        }
      })
      .catch((err) => console.error("Error fetching workflows:", err));
  }, []);

  const handleCardSelect = (workflowId, workFlow) => {
    dispatch(setWorkflow(workFlow));
    setSelectedCardId(workflowId);
    navigate("/setup-new-agent");
  };

  // Filter workflows by active tab (category ID)
  const filteredWorkflows = workflows?.filter(
    (workflow) => workflow?.workflow_category_id === activeTab
  );
  return (
    <>
      <h1 className="text-white text-2xl font-semibold mb-8">
        Browse Workflow Templates
      </h1>
      <div className="w-full bg-agent-card-bg flex flex-col items-center p-4 rounded-xl">
        <div className="w-full">
          {/* Render category tabs */}
          <div className="py-1 w-full flex justify-center items-center mb-3">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center bg-[#424242] rounded-md">
              {categories?.length > 0 ? (
                categories?.map((category) => (
                  <li key={category.id} className="me-2">
                    <button
                      className={`inline-block px-2 py-1 w-[4rem] rounded-md ${
                        activeTab === category.id
                          ? "bg-white text-black"
                          : "hover:text-dark-7 hover:bg-white"
                      }`}
                      type="button"
                      onClick={() => handleAgentSelect(category?.id)} // Pass category ID
                    >
                      {category?.category_name}
                    </button>
                  </li>
                ))
              ) : (
                <li className="text-white">No categories available</li>
              )}
            </ul>
          </div>

          {/* Render workflows */}
          {filteredWorkflows.length > 0 ? (
            <div className="grid grid-cols-3 gap-4 mb-8">
              {filteredWorkflows.map((workflow) => (
                <BrowseAgentCard
                  key={workflow.id}
                  data={workflow}
                  isSelected={selectedCardId === workflow.id}
                  onSelect={() => handleCardSelect(workflow.id, workflow)}
                />
              ))}
            </div>
          ) : (
            <h1 className="text-6xl mt-8 text-center text-white">
              Coming Soon
            </h1>
          )}
        </div>
      </div>
    </>
  );
};
