import React from "react";

export const CloudIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 32.3096L24 24.3096L16 32.3096"
        stroke="black"
        stroke-opacity="0.4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 24.3096V42.3096"
        stroke="black"
        stroke-opacity="0.4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.7799 37.0895C42.7306 36.0261 44.2716 34.3433 45.1597 32.3068C46.0477 30.2703 46.2323 27.996 45.6843 25.8429C45.1363 23.6898 43.8869 21.7806 42.1333 20.4164C40.3796 19.0523 38.2216 18.311 35.9999 18.3095H33.4799C32.8745 15.968 31.7462 13.7942 30.1798 11.9515C28.6134 10.1088 26.6496 8.64524 24.4361 7.67075C22.2226 6.69627 19.817 6.23626 17.4002 6.3253C14.9833 6.41435 12.6181 7.05014 10.4823 8.18487C8.34649 9.3196 6.49574 10.9237 5.06916 12.8767C3.64259 14.8297 2.6773 17.0806 2.24588 19.4603C1.81446 21.84 1.92813 24.2866 2.57835 26.6161C3.22856 28.9455 4.3984 31.0973 5.99992 32.9095"
        stroke="black"
        stroke-opacity="0.4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32 32.3096L24 24.3096L16 32.3096"
        stroke="black"
        stroke-opacity="0.4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
