const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "local"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL;

const config = {
  BASE_URL,
};

export default config;
