import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import FileUpload from "./FileUpload";
import LabelInput from "./LabelInput";
import GoogleAuth from "./GoogleAuth";
import { useNavigate } from "react-router-dom";

const KnowledgeBase = ({
  setPageNumber,
  activeKB,
  setActiveKB,
  formData,
  setFormData,
  handleInputChange,
}) => {
  const navigate = useNavigate();
  const handleButtonClick = (index) => {
    setActiveKB(index);
  };

  return (
    <div className="min-h-screen flex">
      {/* Left Section */}
      <div className="w-[10%] bg-gradient-to-br from-gray-800 to-blue-900 "></div>
      {/* Middle Section */}
      <div className="w-[60%] bg-white flex flex-col justify-center p-16">
        <div className="max-w-xl mx-auto">
          <p className="text-sm text-gray-500 mb-4">02 of 03</p>
          <h1 className="text-3xl font-bold mb-1">
            Set up your Knowledge bases
          </h1>
          <p className="text-dark-6 mb-4">
            Uploading files gives agents context about your company & policies
            Don’t worry you can always add more later!
          </p>
          <p className="text-dark-6 mb-4">
            Our data engine will read your files and understand how to use each
            data table on it’s own!
          </p>

          <div className="grid grid-cols-4 gap-4 mb-4">
            {[
              "File Upload",
              "Notion Page",
              "Website URL",
              "Google Sheet",
              "Database",
            ].map((text, index) => (
              <button
                key={index}
                onClick={() => handleButtonClick(index)}
                className={`border ${
                  activeKB === index ? "border-blue-500" : "border-dark-1"
                } p-4 rounded-lg text-center text-gray-800`}
              >
                {text}
              </button>
            ))}
          </div>
          {/* selected */}
          <div className="mb-4">
            {activeKB === 0 ? (
              <FileUpload
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
              />
            ) : activeKB === 1 ? (
              <LabelInput
                heading={"Enter Notion Page URL"}
                name={"notion"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
            ) : activeKB === 2 ? (
              <LabelInput
                heading={"Website URL"}
                name={"kbWebsiteUrl"}
                formData={formData}
                handleInputChange={handleInputChange}
              />
            ) : activeKB === 3 ? (
              <GoogleAuth
                formData={formData}
                handleInputChange={handleInputChange}
              />
            ) : (
              <></>
            )}
          </div>

          {/* button */}
          <div className="w-full flex justify-end gap-2">
            <button
              onClick={() => setPageNumber(1)}
              className="flex items-center px-4 py-2 text-gray-600 border border-gray-300 rounded-md"
            >
              <BsArrowLeft />
            </button>
            <button
              // onClick={() => setPageNumber(3)}
              onClick={() => navigate("/")}
              className={`flex items-center px-6 py-2 ${
                activeKB !== null ? "bg-primary" : "bg-dark-7"
              } text-white rounded-md`}
              disabled={activeKB === null}
            >
              <span>Done</span>
            </button>
          </div>
        </div>
      </div>
      {/* Right Section */}
      <div className="w-[30%] bg-gradient-to-br from-gray-800 to-blue-900 p-12 flex flex-col justify-center">
        <div className="space-y-4">
          <div className="w-full text-white mb-2">
            <p className="text-xl">Are you stuck</p>
          </div>
          <button className="w-full bg-white text-gray-800 p-4 rounded-md text-left">
            I don't see the tool I want to use
          </button>
          <button className="w-full bg-white text-gray-800 p-4 rounded-md text-left">
            I don't have any tools database
          </button>
          <button className="w-full bg-white text-gray-800 p-4 rounded-md text-left">
            I need help.
          </button>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBase;
