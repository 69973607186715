import React, { useEffect, useState } from "react";
import DashboardIcon from "../assets/icons/DashboardIcon";
import BooksIcon from "../assets/icons/BooksIcon";
import BookIcon from "../assets/icons/BookIcon";
import WrenchIcon from "../assets/icons/WrenchIcon";
import GraghIcon from "../assets/icons/GraghIcon";
import RocketIcon from "../assets/icons/RocketIcon";
import { MdDelete, MdOutlineQuestionMark } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GoPencil } from "react-icons/go";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgents,
  selectAgent,
  selectAgentWorkflow,
  setAgent,
  setAgents,
  setAgentTasks,
  setAgentWorkflow,
  setAgentCurrentWeekSavedTime,
} from "../redux/agentSlice";
import {
  getAllWorkflow,
  getCategories,
  selectUser,
  setWorkflow,
} from "../redux";
import { refreshToken } from "../api/user";
import {
  getAgentCurrentWeekTimeSaveReport,
  getAgentsById,
  getAgentTasksById,
  removeAgent,
  updateAgentStatus,
} from "../api/agent";
import EditModal from "../components/agentDetail/EditModal";
import ConfirmModal from "../components/agentDetail/ConfirmModal";
import { CiLogout } from "react-icons/ci";
import { getFirstLetter } from "../utils";

const Layout = ({ children, showAgents = true }) => {
  const [selectedAgentIndex, setSelectedAgentIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agents = useSelector(getAgents);
  const workflows = useSelector(getAllWorkflow);
  const categories = useSelector(getCategories || []);
  const agent = useSelector(selectAgent);
  const agentWorkflow = useSelector(selectAgentWorkflow);
  const user = localStorage.getItem("userDetails");
  const parse = JSON.parse(user);
  const userDetail = useSelector(selectUser);
  const handleSelect = (agent) => {
    const user = localStorage.getItem("userDetails");
    const parse = JSON.parse(user);
    const workflow = workflows?.find((e) => e.id === agent.workflow_id);
    setSelectedAgentIndex(agent?.id);
    getAgentTasksById(agent?.id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAgentTasks(res?.data));
        }
      })
      .catch((err) => console.log(err));
    getAgentCurrentWeekTimeSaveReport(parse?.client_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAgentCurrentWeekSavedTime(res?.data));
        }
      })
      .catch((err) => console.log(err));
    dispatch(setAgent(agent));
    dispatch(setAgentWorkflow(workflow));
    navigate("/agent-detail");
  };

  const handleStatus = (index) => {
    // Enable loading
    setIsLoading(true);

    const obj = agents[index];
    const updatedObj = {
      id: obj?.id,
      agent_status: obj.agent_status === "active" ? "inactive" : "active",
    };
    // Refresh Token
    refreshToken()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // Update Status
        updateAgentStatus(updatedObj)
          .then((res) => {
            // Set updated agent values
            dispatch(setAgent(res?.data));
            // Get agents by id
            getAgentsById(parse?.client_id)
              .then((res) => {
                if (res?.status === 200) {
                  dispatch(setAgents(res?.data));
                } else if (res?.response?.status === 400) {
                  dispatch(setAgents([]));
                }
              })
              .catch((err) => console.log(err))
              .finally(() => {
                // Disable loading after all operations are done
                setIsLoading(false);
              });
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false); // Ensure loading is disabled even on failure
          });
      });
  };

  const handleDelete = (id) => {
    setIsDeleteModalOpen(true); // Open the confirmation modal
  };

  const confirmDelete = async () => {
    // refresh token
    await refreshToken()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("userDetails", JSON.stringify(res?.data));
        }
      })
      .catch((err) => console.log(err));
    // Agent id
    const agentID = selectedAgentIndex;
    // remove agent
    await removeAgent(agentID)
      .then((res) => {
        getAgentsById(parse?.client_id)
          .then((res) => {
            dispatch(setAgents(res));
            dispatch(setAgent([]));
            navigate("/");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    // Close Modal
    setIsDeleteModalOpen(false); // Close the modal after deletion
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false); // Close the modal without deleting
  };

  const logout = () => {
    // Remove specific items from localStorage
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userDetails");
    navigate("/login");
  };

  // Select first agent by default
  useEffect(() => {
    if (agents?.length > 0) {
      if (selectedAgentIndex === null) {
        if (workflows?.length > 0) {
          const workflow = workflows?.find(
            (e) => e.id === agents[0]?.workflow_id
          );
          const id = agents[0]?.id;
          setSelectedAgentIndex(0); // Set the selected agent's index

          getAgentTasksById(id)
            .then((res) => {
              if (res.status === 200) {
                dispatch(setAgentTasks(res?.data));
              }
            })
            .catch((err) => console.log(err));
          dispatch(setAgent(agents[0]));
          dispatch(setAgentWorkflow(workflow));
        }
      }
    }
  }, [agents, workflows]);

  const [selectedTab, setSelectedTab] = useState("dashboard");

  return (
    <div className="flex">
      {/* left */}
      <div className="w-[5%] bg-[#101010] h-screen flex flex-col items-center justify-between py-4">
        {/* Top Icons */}
        <div className="flex w-full flex-col items-center justify-center space-y-6">
          <div
            className={`icon-container icon-container  w-full flex justify-center items-center py-2 ${
              selectedTab === "dashboard" ? "scale-150 rounded-sm" : "scale-90"
            }`}
            onClick={() => {
              setSelectedTab("dashboard");
              navigate("/");
            }}
          >
            <DashboardIcon />
          </div>

          <div
            className={`icon-container icon-container  flex justify-center items-center py-2 w-full ${
              selectedTab === "workflow" ? "scale-150 rounded-sm" : "scale-90"
            }`}
            onClick={() => {
              setSelectedTab("workflow");
              navigate("/workflow");
            }}
          >
            <BooksIcon />
          </div>

          <div
            className={`icon-container icon-container flex justify-center items-center py-2 w-full ${
              selectedTab === "knowledgebase"
                ? "scale-150 rounded-sm"
                : "scale-90"
            }`}
            onClick={() => {
              setSelectedTab("knowledgebase");
              navigate("/knowledgebase");
            }}
          >
            <BookIcon />
          </div>

          <div
            className={`icon-container icon-container flex justify-center items-center py-2 w-full ${
              selectedTab === "action-and-tools"
                ? "scale-150 rounded-sm"
                : "scale-90"
            }`}
            onClick={() => {
              setSelectedTab("action-and-tools");
              navigate("/action-and-tools");
            }}
          >
            <WrenchIcon />
          </div>

          <div
            className={`icon-container icon-container flex justify-center items-center py-2 w-full ${
              selectedTab === "agent-detail"
                ? "scale-150 rounded-sm"
                : "scale-90"
            }`}
            onClick={() => {
              setSelectedTab("agent-detail");
              navigate("/agent-detail");
            }}
          >
            <GraghIcon />
          </div>

          <div
            className={`icon-container icon-container flex justify-center items-center py-2 w-full ${
              selectedTab === "deployments"
                ? "scale-150 rounded-sm"
                : "scale-90"
            }`}
            onClick={() => {
              setSelectedTab("deployments");
              navigate("/deployments");
            }}
          >
            <RocketIcon />
          </div>
        </div>

        {/* Bottom Icon */}
        <div>
          <div className="icon-container icon-container flex justify-center items-center py-2 w-full">
            <CiLogout
              onClick={logout}
              className="text-white text-2xl cursor-pointer hover:text-gray-400 mb-4"
            />
          </div>
          {/* <MdOutlineQuestionMark className="text-white text-2xl cursor-pointer hover:text-gray-400 mb-4" /> */}

          <div
            onClick={() => navigate("/user-profile")}
            className="h[23px] w-[23px] border-[#00897b] rounded-full bg-[#00897b] text-white text-center cursor-pointer"
          >
            {getFirstLetter(userDetail?.name)}
          </div>
        </div>
      </div>

      {/* center */}
      {showAgents && (
        <div
          style={{ borderRight: "2px solid #171717" }}
          className="w-[20%] bg-black min-h-screen flex flex-col customScroll"
        >
          {/* Top Button */}
          <button
            className="text-white bg-gray-800 py-2 px-4 m-4 rounded-md hover:bg-gray-700"
            onClick={() => {
              dispatch(setWorkflow(null));
              navigate("/setup-new-agent");
            }}
          >
            + New Agent
          </button>

          {/* Agent List */}
          <div className="flex flex-col gap-2 px-4">
            {agents?.length > 0 ? (
              agents?.map((item, index) => (
                <div
                  onClick={() => {
                    handleSelect(item);
                  }}
                  key={index}
                  className={`${
                    selectedAgentIndex === item?.id
                      ? "bg-agent-card-bg"
                      : "bg-black"
                  } text-gray-200 flex px-4 py-3 cursor-pointer rounded-md`}
                >
                  <div className="flex-grow">
                    <div className="mb-1">{item?.name}</div>
                    <div className="text-sm text-gray-400 font-light mb-1">
                      {categories &&
                        categories?.length > 0 &&
                        categories?.find(
                          (category) =>
                            category?.id ===
                            workflows?.find((e) => e.id === item?.workflow_id)
                              ?.workflow_category_id
                        )?.category_name}
                      Workflow
                    </div>
                    <div className="text-sm text-gray-400 font-light">
                      {agents?.deployed_phone_number !== "" ? "Phone" : "Email"}
                      Deployment
                    </div>
                    <div className="w-full flex justify-between mt-2">
                      <button
                        onClick={() => setIsModalOpen(true)}
                        className="bg-bg-1 w-[48%] h-6 flex justify-center items-center px-5 py-0"
                      >
                        <GoPencil />
                      </button>
                      <button
                        onClick={() => handleStatus(index)}
                        className="bg-bg-1 w-[48%] h-6 flex justify-center items-center px-5 py-0"
                      >
                        {item?.agent_status === "active" ? (
                          <>
                            {item?.id === agent?.id && isLoading ? (
                              <div className="customLoader"></div>
                            ) : (
                              <IoIosPause />
                            )}
                          </>
                        ) : (
                          <>
                            {item?.id === agent?.id && isLoading ? (
                              <div className="customLoader"></div>
                            ) : (
                              <IoIosPlay />
                            )}
                          </>
                        )}
                      </button>
                    </div>
                    {/* Show when selected */}
                    {selectedAgentIndex === item?.id && (
                      <div className="w-full flex justify-between mt-2">
                        <button
                          onClick={() => handleDelete(item?.id)}
                          className="bg-bg-1 w-full h-6 flex justify-center items-center px-5 py-0"
                        >
                          <MdDelete />
                        </button>
                      </div>
                    )}
                  </div>
                  <div
                    className={`mt-2 h-3 w-3 rounded-full ${
                      item?.agent_status === "active"
                        ? "bg-green-500"
                        : item?.agent_status === "inactive"
                        ? "bg-gray-500"
                        : "bg-red-500"
                    }`}
                  ></div>
                </div>
              ))
            ) : (
              <div className="text-white">
                <p>No Agents Found</p>
                <p>Please add new agent</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className={`${showAgents ? "w-[75%]" : "w-[95%]"}`}>{children}</div>
      <EditModal
        agent={agent}
        agentWorkflow={agentWorkflow}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <ConfirmModal
          confirmDelete={() => confirmDelete()}
          cancelDelete={() => cancelDelete()}
        />
      )}
    </div>
  );
};

export default Layout;
