import { HammerIcon } from "../../assets/icons";

const ChooseTools = ({
  setPageNumber,
  activeTool,
  setActiveTool,
  formData,
  setFormData,
}) => {
  const handleButtonClick = (index) => {
    setActiveTool(index);
  };
  return (
    <div className="min-h-screen flex">
      {/* Left Section */}
      <div className="w-[10%] bg-gradient-to-br from-gray-800 to-blue-900 "></div>
      {/* Middle Section */}
      <div className="w-[60%] bg-white flex flex-col justify-center p-16">
        <div className="max-w-xl mx-auto">
          <p className="text-sm text-gray-500 mb-4">01 of 03</p>
          <h1 className="text-3xl font-bold mb-1">Choose Your Tools</h1>
          <p className="text-dark-6 mb-4">
            Connecting tools allows your agents to get work done within your
            systems.
          </p>

          <div className="w-full flex items-center space-x-3 py-3 mb-4">
            <div className="bg-[#7AC6FF] p-2 rounded-full">
              <HammerIcon />
            </div>
            <div className="bg-message-bg text-dark-7 p-3 rounded-lg shadow">
              Certain AI workflows require access to tools so it’s best to set
              up as many as possible now
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4 mb-8">
            {[
              "TMS",
              "Loadboard",
              "ELD & Telemetrics",
              "Fleet App",
              "Chrome Extension",
            ].map((text, index) => (
              <button
                key={index}
                onClick={() => handleButtonClick(index)}
                className={`border ${
                  activeTool === index ? "border-blue-500" : "border-dark-1"
                } p-4 rounded-lg text-center text-gray-800`}
              >
                {text}
              </button>
            ))}
          </div>
          <div className="w-full flex justify-end gap-2">
            {/* <button
              onClick={() => setPageNumber(1)}
              className="flex items-center px-4 py-2 text-gray-600 border border-gray-300 rounded-md"
            >
              <BsArrowLeft />
            </button> */}
            <button
              onClick={() => setPageNumber(2)}
              className={`flex items-center px-6 py-2 ${
                activeTool !== null ? "bg-primary" : "bg-dark-7"
              } text-white rounded-md`}
            >
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* Right Section */}
      <div className="w-[30%] bg-gradient-to-br from-gray-800 to-blue-900 p-12 flex flex-col justify-center">
        <div className="space-y-4">
          <div className="w-full text-white mb-2">
            <p className="text-xl">Are you stuck</p>
          </div>
          <button className="w-full bg-white text-gray-800 p-4 rounded-md text-left">
            I don't see the tool I want to use
          </button>
          <button className="w-full bg-white text-gray-800 p-4 rounded-md text-left">
            I don't have any tools database
          </button>
          <button className="w-full bg-white text-gray-800 p-4 rounded-md text-left">
            I need help.
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseTools;
