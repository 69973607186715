import React, { useEffect, useState } from "react";
import {
  getActionAndToolsID,
  postActionAndTools,
} from "../../../api/action-and-tools";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const NameCard = ({ config }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [atList, setAtList] = useState([]);
  const [formData, setFormData] = useState({
    slack_oauth_token: "",
    slack_channel_name: "",
    slack_bot_user_name: "",
    client_id: config?.client_id,
    id: config?.id,
    action_and_tool_id: config?.action_and_tool_id,
  });

  const handleCardClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const parsData = () => {
      if (config?.config_json) {
        const parsedData = JSON.parse(config?.config_json);
        setFormData({
          slack_oauth_token: parsedData.slack_oauth_token,
          slack_channel_name: parsedData.slack_channel_name,
          slack_bot_user_name: parsedData.slack_bot_user_name,
          client_id: config?.client_id,
          id: config?.id,
          action_and_tool_id: config?.action_and_tool_id,
        });
      } else {
        return;
      }
    };
    parsData();
  }, []);

  useEffect(() => {
    const fetchActionAndToolsID = async () => {
      try {
        const fetchATName = await getActionAndToolsID();
        setAtList(fetchATName);
      } catch (error) {
        console.error("Error fetching knowledge base:", error);
      }
    };

    fetchActionAndToolsID();
  }, []);

  const atName = atList?.find((at) => at.id === config?.action_and_tool_id);
  const updateConfig = async (e) => {
    e.preventDefault();
    let isUpdate;
    toast.warning("Updating configrations");
    isUpdate = await postActionAndTools(formData);
    if (isUpdate?.status === 200 || isUpdate?.status === 201) {
      toast.success("Configration is updated");
    }
    toast.error(isUpdate?.error_message);
    handleCloseDialog();
  };
  return (
    <>
      <div
        className="w-[8rem] cursor-pointer px-4 flex min-h-[5rem] justify-center items-center min-w-[5rem] border border-dark-7 bg-agent-card rounded-md"
        onClick={handleCardClick}
      >
        <p className="text-sm font-light">{atName?.name || "Fetching.."}</p>
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 drop-shadow-md ">
          <div className="bg-agent-card dark:bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-400 min-w-44 w-[40%]">
            <h2 className="text-xl font-bold mb-4">
              {formData?.slack_bot_user_name}
            </h2>
            <form>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  {formData?.slack_bot_user_name} OAuth Token
                </label>
                <input
                  type="text"
                  name="slack_oauth_token"
                  value={formData?.slack_oauth_token}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded input bg-transparent"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  {formData?.slack_bot_user_name} Channel Name
                </label>
                <input
                  type="text"
                  name="slack_channel_name"
                  value={formData?.slack_channel_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded input bg-transparent"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  {formData?.slack_bot_user_name} Bot User Name
                </label>
                <input
                  type="text"
                  name="slack_bot_user_name"
                  value={formData?.slack_bot_user_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded input bg-transparent"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleCloseDialog}
                  className="px-4 py-2 mr-2 bg-gray-300 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={(e) => updateConfig(e)}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
