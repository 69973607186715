import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { getAllWorkflow } from "../../redux";

export const WorkFlowCard = ({ name, prompt, deployment, workflowId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const workflows = useSelector(getAllWorkflow);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="bg-agent-card-bg w-[422px] h-auto rounded-md p-7">
      <div className="w-full">
        <div className="bg-[#2c2d32] w-full h-8 flex items-center justify-center">
          <p className="font-medium text-sm text-text-1 text-center">{name}</p>
        </div>
        <div className="w-full flex justify-between mt-4">
          <div className="w-[48%] bg-[#2c2d32] h-8 flex items-center justify-center">
            <p className="font-medium text-sm text-text-1 text-center">
              Workflow: Support
            </p>
          </div>
          <div className="w-[48%] bg-[#2c2d32] h-8 flex items-center justify-center">
            <p className="font-medium text-sm text-text-1 text-center">
              Deployment: {deployment}
            </p>
          </div>
        </div>
        <p className="font-semibold text-md text-white mt-4">
          {/* Efficiently Integrate and Automate Customer Support Tasks */}
          {workflows?.length > 0 &&
            workflows?.find((e) => e.id === workflowId)?.title}
        </p>

        <div id="accordion-collapse" data-accordion="collapse">
          <h2 id="accordion-collapse-heading-1">
            <button
              type="button"
              class="inline-flex items-center gap-1 font-medium text-sm text-text-2 cursor-pointer w-full  rtl:text-right"
              data-accordion-target="#accordion-collapse-body-1"
              aria-expanded={isOpen}
              aria-controls="accordion-collapse-body-1"
              onClick={toggleAccordion}
            >
              <u>Learn More</u> <IoIosArrowDown size={16} />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-1"
            className={`overflow-hidden my-2 transition h-[200px] duration-5000 overflow-y-scroll  ease-in-out ${
              isOpen ? "min-h-[200px]" : "max-h-0"
            }`}
            style={{
              scrollbarWidth: "thin", // For Firefox
              scrollbarColor: "#888 transparent",
            }}
          >
            <div
              id="accordion-collapse-body-1"
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div class="p-5  dark:bg-gray-900">
                {/* <p class="mb-2 text-text-4 dark:text-gray-400">
                  Flowbite is an open-source library of interactive components
                  built on top of Tailwind CSS including buttons, dropdowns,
                  modals, navbars, and more.
                </p>
                <p class="text-text-4 dark:text-gray-400">
                  Check out this guide to learn how to{" "}
                  <a
                    href="/docs/getting-started/introduction/"
                    class="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    get started
                  </a>{" "}
                  and start developing websites even faster with components on
                  top of Tailwind CSS.
                </p> */}

                <div
                  className="feature-actions"
                  dangerouslySetInnerHTML={{
                    __html: `
        ${prompt}
    `,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
